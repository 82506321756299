import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { css, StyleSheet } from 'aphrodite-jss';
import { isIE } from 'react-device-detect';
import classnames from 'classnames';
import { components as Core, ROUTES } from '../../../../core';
import { layoutStyle, spacing, important, baseColors, importantClass } from '../../../../../styles';
import * as Card from '../../Card';
import DatesSwiper from '../../DatesSwiper';
import WithChallengeCardFooterBase, { styles as baseStyles, ICON_SIZE } from './ChallengeCardFooterBase';

class ChallengeCardFooter extends PureComponent {
    static propTypes = {
        i18n: PropTypes.object.isRequired,
        history: PropTypes.object.isRequired,
        challenge: PropTypes.object.isRequired,
        isUpdating: PropTypes.bool,
        challengeId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
        isTrackByDevice: PropTypes.bool.isRequired,
        isTeamSizeIncorrect: PropTypes.bool.isRequired,
        connectedDevices: PropTypes.string,
        joinChallenge: PropTypes.func.isRequired,
        isInvite: PropTypes.bool.isRequired,
        isUnstarted: PropTypes.bool.isRequired,
        isInGracePeriod: PropTypes.bool.isRequired,
        isCompletedNotInGracePeriod: PropTypes.bool.isRequired,
        deviceRequiredText: PropTypes.string.isRequired,
        inviteButtonText: PropTypes.string.isRequired,
        inGraceButtonText: PropTypes.string.isRequired,
        inGracePeriodInfo: PropTypes.object,
        teamSizeIncorrectMessage: PropTypes.string,
        isHomeCarousel: PropTypes.bool,
        dismissChallenge: PropTypes.func,
        isDismissing: PropTypes.bool,
        dismissButtonLabel: PropTypes.string.isRequired,
        deviceRequiredButtonText: PropTypes.string.isRequired,
        datesSwiperTabIndex: PropTypes.oneOfType([PropTypes.string, PropTypes.oneOf([null])]),
        buttonsTabIndex: PropTypes.oneOfType([PropTypes.string, PropTypes.oneOf([null])]),
        blockJoiningChallenge: PropTypes.bool.isRequired
    };

    static defaultProps = {
        isUpdating: false,
        connectedDevices: undefined,
        teamSizeIncorrectMessage: undefined,
        isHomeCarousel: false,
        dismissChallenge: null,
        isDismissing: false,
        inGracePeriodInfo: null,
        datesSwiperTabIndex: '0',
        buttonsTabIndex: '0'
    };

    openDevices = e => {
        e.stopPropagation();
        this.props.history.push(ROUTES.appsDevices());
    };

    goToChallengeDetails = () => {
        this.props.history.push(ROUTES.challengeDetails(this.props.challengeId));
    };

    getDetailsButton = type => (
        <Core.Button
            size="medium"
            className={css(layoutStyle.cardFooterButton)}
            type={type}>
            {this.props.i18n.t('wellbeing.details')}
        </Core.Button>
    );

    joinFeaturedChallenge = e => {
        const { isTeamChallenge, joinFeaturedChallenge, challenge } = this.props;
        if (isTeamChallenge) {
            e.preventDefault();
            e.stopPropagation();
            this.props.history.push(ROUTES.teamChallengeActionSheet(this.props.challenge.challengeId), { challengeId: this.props.challenge.challengeId, challenge });
        } else {
            joinFeaturedChallenge();
        }
    }

    render() {
        const {
            challenge, isHomeCarousel, isInvite, joinChallenge, inviteButtonText, isUnstarted, isInGracePeriod,
            inGracePeriodInfo, isCompletedNotInGracePeriod, isTeamSizeIncorrect, deviceRequiredButtonText,
            teamSizeIncorrectMessage, isTrackByDevice, connectedDevices, isUpdating, deviceRequiredText,
            challenge: { challengeId }, dismissChallenge, isDismissing, dismissButtonLabel,
            dismissFeaturedChallenge, isFeaturedChallenge, isBonusChallenge, datesSwiperTabIndex, buttonsTabIndex, blockJoiningChallenge, i18n } = this.props;

        if (isFeaturedChallenge) {
            return (
                <div className={classnames(css(styles.featuredBtnsContainer), isBonusChallenge && css(styles.bonusMargin))}>
                    {!blockJoiningChallenge ?
                        <Core.Button
                            fullWidth={true}
                            size="medium"
                            onPress={this.joinFeaturedChallenge}>
                            {i18n.t('joinChallenge')}
                        </Core.Button>
                        : 
                        <div></div>
                    }
                    <div
                        className={css(styles.dismissBtnText)}
                        onClick={dismissFeaturedChallenge}>
                        {i18n.t('button_dismiss')}
                    </div>
                </div>
            );
        }

        if (isUnstarted) return null;

        if (isInvite) {
            return (
                <Card.Footer isGrey={false} className={css(styles.wrapper)}>
                    {this.getDetailsButton('outlined')}
                    {!blockJoiningChallenge ?
                        <Core.Button
                            tabIndex={buttonsTabIndex}
                            type="outlined"
                            size="medium"
                            className={css(layoutStyle.cardFooterButton)}
                            onPress={joinChallenge}>
                            {inviteButtonText}
                        </Core.Button>
                        :
                        <div></div>
                    }
                </Card.Footer>
            );
        }

        if (isInGracePeriod) {
            const { firstLabel, secondLabel, thirdLabel } = inGracePeriodInfo;
            return (
                <Card.Footer isGrey={false} className={css(styles.wrapper, styles.mb0, styles.noTopPadding)}>
                    <div className={css(styles.inGraceInfo)}>
                        <span className={css(layoutStyle.textCenter)}>
                            {firstLabel}
                            <Core.Icon
                                type="fa"
                                fill="regular"
                                size={ICON_SIZE}
                                name="hourglass-half"
                                color={baseColors.warn}
                                className={css(styles.iconContainer)}
                            />
                            <span className={css(styles.boldText)}>{secondLabel}</span>
                            {thirdLabel}
                        </span>
                    </div>
                </Card.Footer>
            );
        }

        if (isCompletedNotInGracePeriod) {
            return (
                <Card.Footer isGrey={false} isBorder={true} className={css(styles.wrapper)} isDismiss={true}>
                    <Core.Button
                        tabIndex={buttonsTabIndex}
                        type="text"
                        size="medium"
                        onPress={dismissChallenge}
                        isLoading={isDismissing}
                        className={css(styles.dismissButton)}>
                        {dismissButtonLabel}
                    </Core.Button>
                </Card.Footer>
            );
        }

        if (isTeamSizeIncorrect) {
            return (
                <Card.Footer isGrey={false} isColumn={true} className={css(styles.wrapper)}>
                    <p className={css(styles.footerMessage, styles.footerMessageDanger, styles.incorrectTeamSize, isIE && styles.incorrectTeamSizeIE)}>
                        {teamSizeIncorrectMessage}
                    </p>
                </Card.Footer>
            );
        }

        if (isTrackByDevice) {
            if (connectedDevices) {
                return (
                    <Card.Footer isBorder={true} isGrey={false} isColumn={true} className={css(styles.webRemoveFooterSpacing)}>
                        <div className={css(layoutStyle.flexRowCenter, layoutStyle.flexSpaceAround, layoutStyle.fw)}>
                            <DatesSwiper
                                isCurrentWeek={true}
                                isDisabled={isUpdating}
                                challengeId={challengeId}
                                isHomeCarousel={isHomeCarousel}
                                tabIndex={datesSwiperTabIndex}
                            />
                        </div>
                    </Card.Footer>
                );
            }

            return (
                <Card.Footer isBorder={true} isColumn={true} isGrey={false} className={css(styles.wrapper)}>
                    <p className={css(styles.footerMessage, styles.footerMessageDanger)}>
                        {deviceRequiredText}
                    </p>
                    <div className={css(layoutStyle.flexRow, layoutStyle.fw, styles.mb0)}>
                        <Core.Button
                            tabIndex={buttonsTabIndex}
                            isPrevended={true}
                            size="medium"
                            className={css(layoutStyle.cardFooterButton, styles.bgDangerColor)}
                            onPress={this.openDevices}>
                            {deviceRequiredButtonText}
                        </Core.Button>
                    </div>
                </Card.Footer>
            );
        }

        if (!isTrackByDevice && challenge.recommendedTrackingApps.length) {
            return (
                <Card.Footer isBorder={true} isColumn={true} isGrey={false} className={css(styles.webRemoveFooterSpacing)}>
                    <div className={css(layoutStyle.flexRowCenter, layoutStyle.flexSpaceAround, layoutStyle.fw)}>
                        <DatesSwiper
                            isCurrentWeek={true}
                            isDisabled={isUpdating}
                            challengeId={challengeId}
                            isHomeCarousel={isHomeCarousel}
                            tabIndex={datesSwiperTabIndex}
                        />
                    </div>
                </Card.Footer>
            );
        }

        return (
            <Card.Footer isBorder={true} isColumn={true} isGrey={false} className={css(styles.webRemoveFooterSpacing)}>
                <div className={css(layoutStyle.flexRowCenter, layoutStyle.flexSpaceAround, layoutStyle.fw)}>
                    <DatesSwiper
                        isCurrentWeek={true}
                        isDisabled={isUpdating}
                        challengeId={challengeId}
                        isHomeCarousel={isHomeCarousel}
                        tabIndex={datesSwiperTabIndex}
                    />
                </div>
            </Card.Footer>
        );
    }
}

export default withRouter(WithChallengeCardFooterBase(ChallengeCardFooter));

const styles = StyleSheet.create({
    ...baseStyles,
    footerMessage: {
        ...baseStyles.footerMessage,
        paddingBottom: 0,
        marginBottom: spacing.s1,
        marginRight: spacing.s3,
        marginLeft: spacing.s3,
    },
    wrapper: {
        marginTop: 'auto',
    },
    webRemoveFooterSpacing: {
        paddingBottom: important(0),
        paddingTop: important(0)
    },
    noTopPadding: {
        paddingTop: 0,
    },
    bgDangerColor: {
        backgroundColor: important(baseColors.dangerDarker),
    },
    dismissButton: importantClass({
        paddingHorizontal: spacing.s3,
        paddingVertical: spacing.s3,
        '&:hover': {
            backgroundColor: 'transparent',
        },
    }),
    iconContainer: {
        marginRight: spacing.s0 + 2,
        marginLeft: spacing.s0,
    },
    incorrectTeamSize: {
        textAlign: 'center',
        marginBottom: spacing.s2,
        marginRight: spacing.s5,
        marginLeft: spacing.s5,
    },
    incorrectTeamSizeIE: {
        width: '100%',
        paddingRight: spacing.s5,
        paddingLeft: spacing.s5,
    },
    featuredBtnsContainer: {
        ...baseStyles.featuredBtnsContainer,
        marginTop: spacing.s6,
    },
    bonusMargin: {
        marginTop: spacing.s1
    }
});
