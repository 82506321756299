import React, { Component } from 'react';

import { css, StyleSheet } from 'aphrodite-jss';
import _ from 'lodash';
import { isIE } from 'react-device-detect';
import PropTypes from 'prop-types';
import { components as Core, refreshCacheAndReload, ROUTES, Modal } from '../../../core';
import WithAccountSettingsBase, { styles as baseStyles } from './AccountSettingsBase';
import { baseColors, importantStyles, layoutStyle, spacing } from '../../../../styles';
import { components as Auth } from '../../../auth';

class AccountSettings extends Component {
    static propTypes = {
        updateUserValue: PropTypes.func.isRequired,
        isLoading: PropTypes.bool,
        isSaving: PropTypes.bool,
        isHRIS: PropTypes.bool,
        errors: PropTypes.object.isRequired,
        i18n: PropTypes.object.isRequired,
        hasUnsavedChanges: PropTypes.func,
        changeLocation: PropTypes.func.isRequired,
        preferredNameField: PropTypes.array.isRequired,
        nameProfileFields: PropTypes.array.isRequired,
        languages: PropTypes.array.isRequired,
        currentLanguage: PropTypes.object.isRequired,
        actions: PropTypes.object.isRequired,
        generalSubmit: PropTypes.func.isRequired,
        saveLanguageChanges: PropTypes.func.isRequired,
        deleteAccountTitle: PropTypes.string.isRequired,
        deleteAccountText: PropTypes.string.isRequired,
        deleteAccountButtonText: PropTypes.string.isRequired,
        deleteAccount: PropTypes.func.isRequired,
        onEmailChange: PropTypes.func.isRequired,
    };

    static defaultProps = {
        isLoading: false,
        isSaving: false,
        isHRIS: false,
        hasUnsavedChanges: null
    };

    constructor(props) {
        super(props);
    }

    componentDidMount() {
        if (this.props.onRef) this.props.onRef(this);
        this.props.actions.resetIsLanguageChanged();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.isUpdatingUser && !this.props.isUpdatingUser && !isIE) {
            refreshCacheAndReload();
        }
    }

    componentWillUnmount() {
        if (this.props.onRef) this.props.onRef(null);
    }

    get nameEditor() {
        const { i18n, errors, isLoading, isSaving, preferredNameField, nameProfileFields } = this.props;

        return (
            <div className={css(layoutStyle.flex1)}>
                <div>
                    <div className={css(styles.container)}>
                        {_.map(nameProfileFields, item => (
                            <Core.Input
                                label={i18n.t(`${item.name}`)}
                                onChangeText={this.props.updateUserValue(item)}
                                disabled={item.readOnly}
                                fullWidth={true}
                                value={item.value}
                                error={!!errors[item.stateKey]}
                                helperText={errors[item.stateKey]}
                                key={item.name}
                                className={nameProfileFields.indexOf(item) === 0 ? css(styles.inputContainer) : null}
                            />
                        ))}
                    </div>
                    {preferredNameField ? (
                        <Core.Input
                            label={i18n.t(`${preferredNameField.name}`)}
                            onChangeText={this.props.updateUserValue(preferredNameField)}
                            disabled={preferredNameField.readOnly}
                            fullWidth={true}
                            value={preferredNameField.value}
                            error={!!errors[preferredNameField.stateKey]}
                            helperText={errors[preferredNameField.stateKey]}
                            key={preferredNameField.name}
                            containerStyle={css(styles.inputContainer)}
                        />
                    ) : null}
                </div>
                <Core.BlockingLoading isLoading={isLoading || isSaving} />
            </div>
        );
    }

    get locationEditor() {
        return (
            <div className={css(layoutStyle.flex1)}>
                <Auth.LocationDetails
                    hasUnsavedChanges={this.props.hasUnsavedChanges}
                    changeLocationCallback={this.props.changeLocation}
                    isRegistration={false}
                    errors={this.props.errors}
                    isSettingsPage={true}
                />
                <Core.BlockingLoading isLoading={this.props.isLoading || this.props.isSaving} />
            </div>
        );
    }

    onEmailChange = event => {
        this.props.onEmailChange(event.target.value);
    };

    goBack = () => this.props.history.push(_.get(this.props, 'lastLocation.pathname') || ROUTES.profileEditor());

    onLanguageChange = language => this.props.onLanguageChange(language.id);

    generalSubmit = () => {
        this.props.saveLanguageChanges();
        this.props.generalSubmit();
    }

    showDialog = () => {
        this.closeDialog = Modal.open(
            () => this.deleteModal(),
            {},
            { isContainer: true, contentClassName: css(styles.modalContent) }
        );
    };

    deleteModal() {
        const { i18n, deleteAccount } = this.props;
        return (
            <div>
                <Core.SubHeader title={i18n.t('deleteAccount')} />
                <p>{i18n.t('confirmDeleteAccount')}</p>
                <Core.Input
                    autoFocus={true}
                    label={i18n.t('email')}
                    type="email"
                    fullWidth={true}
                    onChange={this.onEmailChange}
                />
                <Core.DialogActions>
                    <Core.Button onPress={this.closeDialog} type="text">
                        {i18n.t('button_cancel')}
                    </Core.Button>
                    <Core.Button onPress={deleteAccount} type="text">
                        {i18n.t('button_delete')}
                    </Core.Button>
                </Core.DialogActions>
            </div>
        );
    }

    render() {
        const { i18n, languages, currentLanguage, deleteAccountText, deleteAccountTitle, deleteAccountButtonText } = this.props;

        const languagesArray = languages ? Object.keys(languages).map(key => ({ label: languages[key], id: key })) : [];

        return (
            <div>
                <Core.SubHeader
                    title={i18n.t('account')}
                    noTopPadding={true}
                    noLeftPadding={true}
                />
                {this.nameEditor}
                <Core.Input
                    fullWidth={true}
                    label={this.props.i18n.t('email')}
                    id="username"
                    disabled={true}
                    value={this.props.userEmail}
                />
                {languagesArray.length > 1 ? (
                    <Core.SelectField
                        id="languageField"
                        options={languagesArray}
                        message={i18n.t('language')}
                        fieldName={i18n.t('language')}
                        selected={currentLanguage}
                        onChange={this.onLanguageChange}
                        checkboxType={Core.Checkbox.TYPES.simple}
                        className={css(styles.languageField)}
                    />
                )
                    : null}
                {this.locationEditor}
                <Core.Button id="saveChangesBtn" size={Core.Button.SIZES.medium} onPress={this.generalSubmit} className={css(styles.button)}>{this.props.i18n.t('savingUserToast.success.title')}</Core.Button>
                <div className={css(styles.divider)} />
                <div className={css(styles.deleteAccountSection)}>
                    <p className={css(styles.deleteAccountSectionTitle)}>{deleteAccountTitle}</p>
                    <p className={css(styles.deleteAccountSectionText)}>{deleteAccountText}</p>
                    <Core.Button id="deleteAccountBtn" size={Core.Button.SIZES.medium} onPress={this.showDialog} className={css(styles.button, styles.deleteButton)}>{deleteAccountButtonText}</Core.Button>
                </div>
            </div>
        );
    }
}

const styles = StyleSheet.create({
    ...baseStyles,
    ...importantStyles(
        {
            button: {
                marginTop: spacing.s7,
                paddingLeft: spacing.s5,
                paddingRight: spacing.s5,
                marginBottom: spacing.s5
            },
            inputContainer: {
                paddingRight: spacing.s3
            },
            deleteButton: {
                marginTop: spacing.s5,
                backgroundColor: baseColors.dangerDarker,
            }
        }),
    container: {
        flex: 1,
        display: 'flex',
        paddingLeft: -spacing.s3
    },
    languageField: {
        marginBottom: spacing.s7
    },
    deleteAccountSection: {
        paddingTop: spacing.s5,
    },
});

export default WithAccountSettingsBase(AccountSettings);
