import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { StyleSheet } from 'aphrodite-jss';
import { AsyncComponent, components as Core, Modal, Storage, tracker } from '../../../core';
import { EVENT_ENTITY_TYPES } from '../../constants';
import AllTab from '../AllTab';
import MyTab from '../MyTab';
import WithEventsAllBase, { styles as baseStyles } from './EventsAllBase';
import { IS_EVENT_PAGE } from '../../../shared/constants';

class EventsAll extends PureComponent {
    static propTypes = {
        i18n: PropTypes.object.isRequired,
        tabs: PropTypes.array,
        selectedTab: PropTypes.object,
        selectActiveTab: PropTypes.func,
        isLoadingAllEvents: PropTypes.bool,
        isLoadingMyEvents: PropTypes.bool,
        isLoading: PropTypes.bool,
        allEventsLength: PropTypes.number,
        title: PropTypes.string.isRequired,
        exploreLoadingStatus: PropTypes.object
    };

    static defaultProps = {
        isLoadingAllEvents: false,
        isLoadingMyEvents: false,
        isLoading: false,
        tabs: undefined,
        selectedTab: undefined,
        selectActiveTab: undefined,
        allEventsLength: 0,
        exploreLoadingStatus: {}
    };

    constructor(props) {
        super(props);
        this.isFirstLoading = true;
        this.tabReselected = false;
    }

    componentDidUpdate(prevProps) {
        if (prevProps.isLoading && !this.props.isLoading) {
            this.isFirstLoading = false;
        }
    }

    goAddEvent = () => {
        tracker.logEvent('add_event', { plus: false });
        Modal.open(AsyncComponent(() => import('../CreateEvent')), { isModal: true }, {
            cancelable: false,
            isNoPadding: true,
            isContainer: true,
            isFullHeight: true
        });
    };

    get headerComponent() {
        const { i18n, title } = this.props;
        return (
            <React.Fragment>
                <Core.EntityDetailsHeader
                    hasBackButton={true}
                    backButtonAction={() => {
                        Storage.setItem(IS_EVENT_PAGE, true);
                    }}
                    rightComponent={(
                        <Core.CreateEntityButton
                            id="createEventBtn"
                            onPress={this.goAddEvent}
                            text={i18n.t('createEvent.createNew')}
                        />
                    )}
                    title={title}
                />
                <Core.SubHeader
                    title={title}
                    noTopPadding={true}
                />
            </React.Fragment>
        );
    }

    showSelectedTab = () => {
        switch (this.props.selectedTab.id) {
            case EVENT_ENTITY_TYPES.MY_ITEMS:
                return (
                    <MyTab
                        isLoading={this.props.isLoadingMyEvents && this.isFirstLoading}
                        allEventsLength={this.props.allEventsLength}
                        exploreLoadingStatus={this.props.exploreLoadingStatus}
                    />
                );
            case EVENT_ENTITY_TYPES.ALL_ITEMS:
            default:
                return (
                    <AllTab
                        isLoading={this.props.isLoadingAllEvents && this.isFirstLoading}
                        allEventsLength={this.props.allEventsLength}
                        exploreLoadingStatus={this.props.exploreLoadingStatus}
                    />
                );
        }
    };

    selectActiveTab = tab => {
        if (!this.tabReselected) {
            this.isFirstLoading = true;
            this.tabReselected = true;
        }
        this.props.selectActiveTab(tab);
    };

    render() {
        return (
            <Core.Layout.WiderContainer>
                {this.headerComponent}
                <Core.TabsWithContent
                    tabs={this.props.tabs}
                    onChange={this.selectActiveTab}
                    activeTab={this.props.selectedTab}
                    tabStyle={styles.tabs}
                    isStickyUnderNavBar={true}>
                    {this.showSelectedTab()}
                </Core.TabsWithContent>
            </Core.Layout.WiderContainer>
        );
    }
}

export default WithEventsAllBase(EventsAll);

const styles = StyleSheet.create({
    ...baseStyles
});
