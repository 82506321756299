import React from 'react';
import PropTypes from 'prop-types';
import { isIE } from 'react-device-detect';
import { withRouter } from 'react-router-dom';
import { StyleSheet, css } from 'aphrodite-jss';
import { withLastLocation } from 'react-router-last-location';
import _ from 'lodash';
import { baseColors, layoutStyle, spacing, important, challengeDetailsImageSize } from '../../../../styles';
import Icon, { IconButton } from '../Icon';
import Button from '../Button';
import translate from '../../services/translate';
import { HEADER_TRANSITION_DURATION, ROUTES, SCROLL_DELAY } from '../../constants';

const HEADER_SHOWING_SCROLL_OFFSET = 20;
const BACK_BUTTON_WIDTH = 84;
const SCROLL_OFFSET_CHALLENGE_DETAILS = challengeDetailsImageSize - 58;
const TITLE_OFFSET_IE = 10;

class EntityDetailsHeader extends React.PureComponent {
    constructor(props) {
        super(props);
        this.detectScroll = _.throttle(this.handleScroll.bind(this), SCROLL_DELAY);
        this.state = { headerBorder: false, hasTitle: false, rightButtonWidth: BACK_BUTTON_WIDTH };
        this.rightRef = React.createRef();
    }

    componentDidMount() {
        window.addEventListener('scroll', this.detectScroll);
        if (this.rightRef.current.offsetWidth > BACK_BUTTON_WIDTH) {
            this.setState({ rightButtonWidth: this.rightRef.current.offsetWidth });
        }
    }

    componentWillUnmount() {
        window.removeEventListener('scroll', this.detectScroll);
    }

    handleScroll = () => this.setState({
        headerBorder: window.pageYOffset > this.headerBorderOffset,
        hasTitle: window.pageYOffset > this.titleOffset
    });

    get headerBorderOffset() {
        return this.props.isChallengeDetailsPage ? SCROLL_OFFSET_CHALLENGE_DETAILS : 0;
    }

    get titleOffset() {
        return this.props.isChallengeDetailsPage ? SCROLL_OFFSET_CHALLENGE_DETAILS : HEADER_SHOWING_SCROLL_OFFSET;
    }

    get setWhiteBackground() {
        if (this.props.isChallengeDetailsPage) {
            return this.state.headerBorder ? styles.challengeScrollNavbar : null;
        }
        return null;
    }

    get containerShadow() {
        return (this.state.headerBorder && this.props.hasScrollShadow)
            ? styles.shadow : null;
    }

    goBack = () => {
        const { lastLocation, isChallengeDetailsPage, history, backButtonAction } = this.props;
        if (backButtonAction) {
            backButtonAction();
        }
        const isLastLocationTeams = lastLocation && /teams/.test(lastLocation.pathname);
        const shouldGoHome = isChallengeDetailsPage && isLastLocationTeams;
        (lastLocation && !shouldGoHome) ? history.goBack() : history.push(ROUTES.home());
    }

    leftComponent = className => (
        <IconButton
            type="fa"
            color={this.state.headerBorder ? baseColors.secondary : baseColors.white}
            size={spacing.s7}
            isSmall={true}
            hasRoundHover={true}
            name="angle-left"
            onClick={this.goBack}
            className={css(!this.state.headerBorder && className)}
        />
    )

    render() {
        const { hasBackButton, backUrl, rightNavIconProps, rightComponent, i18n, title, isOnboarding,
            headerContainerClassName, navbarButtonsClassName, isChallengeDetailsPage } = this.props;
        const titleStyle = {
            width: `calc(100% - ${this.state.rightButtonWidth*2}px)`,
            left: this.state.rightButtonWidth,
            bottom: isChallengeDetailsPage && isIE && TITLE_OFFSET_IE,
        };
        return (
            <div
                className={css(layoutStyle.stickyContainer, styles.headerContainer, this.containerShadow,
                    isOnboarding && layoutStyle.stickyContainerOnboarding, headerContainerClassName, this.setWhiteBackground)}>
                { isChallengeDetailsPage ? this.leftComponent(navbarButtonsClassName) : null}
                {hasBackButton && !isChallengeDetailsPage ? (
                    <Button
                        id="backBtnOnEnityDetails"
                        onPress={backUrl ? backUrl : this.goBack}
                        type="outlined"
                        className={css(layoutStyle.createEntityButton, styles.backButton)}>
                        <Icon
                            name="angle-left"
                            type="fa"
                            size={spacing.s7}
                            color={baseColors.secondary}
                        />
                        <span>{i18n.t('back')}</span>
                    </Button>
                )
                    : null
                }
                {this.state.hasTitle? <div className={css(styles.title)} style={titleStyle}>{title}</div> : null}
                <div ref={this.rightRef}>
                    {rightComponent ? rightComponent : null}
                    {rightNavIconProps && !rightComponent ? (
                        <IconButton
                            id="moreBtn"
                            type="fa"
                            color={isChallengeDetailsPage && !this.state.headerBorder ? baseColors.white : baseColors.secondary}
                            size={spacing.s7}
                            isSmall={true}
                            hasRoundHover={!rightNavIconProps.size}
                            className={css(!this.state.headerBorder && navbarButtonsClassName)}
                            {...rightNavIconProps}
                        />
                    ) : null}
                </div>
            </div>
        );
    }
}

EntityDetailsHeader.propTypes = {
    i18n: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
    hasBackButton: PropTypes.bool,
    backUrl: PropTypes.string,
    rightNavIconProps: PropTypes.object,
    rightComponent: PropTypes.node,
    title: PropTypes.string,
    isOnboarding: PropTypes.bool,
    lastLocation: PropTypes.object,
    isChallengeDetailsPage: PropTypes.bool,
    navbarButtonsClassName: PropTypes.string,
    headerContainerClassName: PropTypes.string,
    hasScrollShadow: PropTypes.bool,
    backButtonAction: PropTypes.func
};

EntityDetailsHeader.defaultProps = {
    hasBackButton: false,
    backUrl: undefined,
    rightNavIconProps: null,
    rightComponent: null,
    title: undefined,
    isOnboarding: false,
    lastLocation: null,
    isChallengeDetailsPage: false,
    navbarButtonsClassName: null,
    headerContainerClassName: null,
    hasScrollShadow: true,
    backButtonAction: undefined
};

const styles = StyleSheet.create({
    headerContainer: {
        minHeight: spacing.s9, //TODO: need to consistency with other sticky headers
        display: 'flex',
        zIndex: 999,
        justifyContent: 'space-between',
        alignItems: 'center',
        background: baseColors.white
    },
    backButton: {
        paddingLeft: important(spacing.s0, true)
    },
    shadow: {
        boxShadow: `0 8px 6px -6px rgba(0, 0, 0, ${0.3})`,
        transition: `all ${HEADER_TRANSITION_DURATION}ms ease-out 0ms`
    },
    title: {
        position: 'absolute',
        textAlign: 'center',
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        color: baseColors.black
    },
    challengeScrollNavbar: {
        backgroundColor: baseColors.white,
        paddingTop: 0,
    },
});

export default withLastLocation(withRouter(translate()(EntityDetailsHeader)));
