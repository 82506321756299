import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import _ from 'lodash';
import { css, StyleSheet } from 'aphrodite-jss';
import { withRouter } from 'react-router-dom';
import { spacing, layoutStyle, exploreImageHeight } from '../../../../styles';
import { components as Core, constants as coreConstants } from '../../../core';
import { components as Invitations } from '../../../invitations';
import WithEventCarouselItemBase, { styles as baseStyles } from './EventCarouselItemBase';
import EventBonus from '../EventBonus/EventBonus';

class EventCarouselItem extends PureComponent {
    static propTypes = {
        image: PropTypes.string,
        isInvitation: PropTypes.bool,
        invitedBy: PropTypes.object,
        onPressSingle: PropTypes.func.isRequired,
        item: PropTypes.object.isRequired,
        fullEventDate: PropTypes.string.isRequired,
    };

    static defaultProps = {
        isInvitation: false,
        invitedBy: null,
        image: '',
    };

    get eventHeader() {
        const { isInvitation, invitedBy } = this.props;
        return (isInvitation && invitedBy ? (
            <Invitations.Invitation
                containerClassName={css(styles.invitationContainer)}
                invitedBy={invitedBy}
                type={coreConstants.ENTITIES.event}
            />
        ) : null
        );
    }

    render() {
        const { fullEventDate, image, item, onPressSingle, joinedTag } = this.props;
        return (
            <Core.TouchableItem
                id={_.camelCase(`${item.eventName}Container`)}
                onPressSingle={onPressSingle}
                image={image}
                imageHeight={exploreImageHeight}
                headerComponent={this.eventHeader}
                isCarouselItem={true}
                isFadedImage={item.isInvitation}
                joinedTag={joinedTag}>
                <div className={css(layoutStyle.carouselInfoContainer, styles.carouselInfoContainer)}>
                    <Core.ExploreItemMeta iconName="calendar-alt" text={fullEventDate} />
                    <Core.ExploreItemTitle text={item.eventName} />
                    <div className={css(styles.eventTimeWrapper)}>
                        <Core.ExploreItemMembers members={item.eventAttendees} isEvent={true} />
                    </div>
                    <div className={css(styles.bonusSection)}>
                        <EventBonus eventId={item.eventId} />
                    </div>
                </div>
            </Core.TouchableItem>
        );
    }
}

export default withRouter(WithEventCarouselItemBase(EventCarouselItem));

export const styles = StyleSheet.create({
    ...baseStyles,
    eventTimeWrapper: {
        ...baseStyles.eventTimeWrapper,
        display: 'flex'
    },
    invitationContainer: {
        marginRight: spacing.s1,
        marginLeft: spacing.s1
    },
    carouselInfoContainer: {
        marginTop: spacing.s2,
        marginLeft: spacing.s1
    }
});
