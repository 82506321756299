import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { StyleSheet, css } from 'aphrodite-jss';
import classNames from 'classnames';
import ReactPlayer from 'react-player';
import Image from '../Image';
import WithTouchableItemBase, { styles as baseStyles } from './TouchableItemBase';
import * as onKeyPress from '../../services/onKeyPress';
import { baseColors, spacing, important, layoutStyle, containerStyle } from '../../../../styles';

const contentHeroCaruselGradint = ' linear-gradient(270deg, rgba(0, 0, 0, 0) 50%, rgba(0, 0, 0, 0.35) 65%, rgba(0, 0, 0, 0.5) 75%,rgba(0, 0, 0, 0.75) 100%)';

class TouchableItem extends PureComponent {
    static propTypes = {
        image: PropTypes.string,
        separator: PropTypes.bool,
        videoUrl: PropTypes.string,
        featuredTag: PropTypes.node,
        isFadedImage: PropTypes.bool,
        onPressSingle: PropTypes.func,
        isCarouselItem: PropTypes.bool,
        isVideoPlayable: PropTypes.bool,
        headerComponent: PropTypes.node,
        imageClassName: PropTypes.string,
        isFeaturedSection: PropTypes.bool,
        imageInnerComponent: PropTypes.node,
        containerClassName: PropTypes.string,
        hasImageObjectFitContain: PropTypes.bool,
        innerContainerClassName: PropTypes.string,
        children: PropTypes.oneOfType([PropTypes.element, PropTypes.array]).isRequired,
        imageWidth: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
        imageHeight: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
        isGradientFadedImage: PropTypes.bool,
        isLibraryPage: PropTypes.bool,
        onImageContent: PropTypes.node,
        isContentHeroCarusel: PropTypes.bool,
        tabIndex: PropTypes.oneOfType([PropTypes.string, PropTypes.oneOf([null])]),
        role: PropTypes.string,
        hasSmallerFocusOffset: PropTypes.bool,
        videoPreviewTabIndex: PropTypes.string,
        videoUrlRole: PropTypes.string,
        videoUrlTabIndex: PropTypes.oneOfType([PropTypes.string, PropTypes.oneOf([null])]),
        id: PropTypes.string
    };

    static defaultProps = {
        videoUrl: '',
        separator: false,
        image: undefined,
        featuredTag: null,
        onPressSingle: null,
        isFadedImage: false,
        isVideoPlayable: true,
        headerComponent: null,
        isCarouselItem: false,
        isFeaturedSection: false,
        imageInnerComponent: null,
        imageClassName: undefined,
        containerClassName: undefined,
        hasImageObjectFitContain: false,
        innerContainerClassName: undefined,
        isGradientFadedImage: false,
        isLibraryPage: false,
        onImageContent: null,
        isContentHeroCarusel: false,
        tabIndex: '0',
        role: 'button',
        hasSmallerFocusOffset: false,
        videoPreviewTabIndex: '-1',
        videoUrlRole: 'none',
        videoUrlTabIndex: null,
        id: undefined
    };

    constructor(props) {
        super(props);
        this.state = {
            isVideoPlaying: false,
        };
    }

    playVideo = event => {
        if (!this.props.isVideoPlayable) return;
        event.stopPropagation();
        this.setState(prevState => {
            if (!prevState.isVideoPlaying) {
                return { isVideoPlaying: true };
            }
        });
    };

    get borderClassName() {
        return this.props.separator ? styles.border : null;
    }

    get focusClassName() {
        return this.props.hasSmallerFocusOffset ? containerStyle.listItemfocusVisibleWithSmallerOutlineOffset : containerStyle.listItemfocusVisible;
    }

    render() {
        const { hasImageObjectFitContain, onPressSingle, containerClassName, headerComponent, innerContainerClassName, isFeaturedSection,
            isCarouselItem, imageClassName, imageWidth, imageHeight, image, imageInnerComponent, isFadedImage, children, videoUrl,
            featuredTag, isGradientFadedImage, isLibraryPage, onImageContent, isContentHeroCarusel, joinedTag, tabIndex, role, videoPreviewTabIndex, videoUrlRole, videoUrlTabIndex, id } = this.props;
        return (
            <div
                id={id}
                tabIndex={tabIndex}
                role={role}
                onKeyDown={onKeyPress.enter(onPressSingle)}
                onClick={onPressSingle}
                className={classNames(css(styles.mainContainer, this.borderClassName, this.focusClassName), containerClassName)}>
                {headerComponent}
                <div className={innerContainerClassName}>
                    <div className={classNames(css(isCarouselItem ? styles.carouselImageArea : styles.imageArea), imageClassName)}>
                        <div className={classNames(css(styles.imageContainerWithShadow))} style={{ width: imageWidth, height: imageHeight }}>
                            <div className={isLibraryPage ? css(styles.featuredTag) : null}>{featuredTag}</div>
                            {joinedTag}
                            {image && !videoUrl ? (
                                <Image
                                    src={image}
                                    className={css(styles.image, isFeaturedSection ? styles.smallerRadius : null)}
                                    resizeMode={hasImageObjectFitContain ? 'contain' : undefined}
                                />
                            ) : null}
                            {videoUrl ? (
                                <div
                                    role={videoUrlRole}
                                    onClick={this.playVideo}
                                    className={css(layoutStyle.fh, styles.playerRadius)}
                                    onKeyDown={onKeyPress.enter(this.playVideo)}
                                    tabIndex={videoUrlTabIndex}>
                                    <ReactPlayer
                                        width="100%"
                                        height="100%"
                                        url={videoUrl}
                                        controls={true}
                                        playIcon={onImageContent}
                                        playing={this.state.isVideoPlaying}
                                        previewTabIndex={videoPreviewTabIndex}
                                        light={!this.state.isVideoPlaying && image}
                                    />
                                </div>
                            ) : null}
                            {imageInnerComponent}
                            {isFadedImage ? <div className={css(styles.fadedImageBackground)} /> : null}
                            {isGradientFadedImage ? <div className={css(styles.linearGradientWeb, isContentHeroCarusel && styles.linearGradientForContentHeroCarusel)} /> : null}
                        </div>
                    </div>
                    {children}
                </div>
            </div>
        );
    }
}

const styles = StyleSheet.create({
    ...baseStyles,
    mainContainer: {
        ...baseStyles.mainContainer,
        cursor: 'pointer',
        borderBottomStyle: 'solid',
        borderBottomColor: 'transparent'
    },
    border: {
        borderBottomColor: baseColors.grey80,
    },
    imageContainerWithShadow: {
        ...baseStyles.imageContainerWithShadow,
        '&:active': {
            transform: 'scale(0.95)',
            transition: 'all 0.3s'
        },
        position: 'relative'
    },
    smallerRadius: {
        borderRadius: important(spacing.s0),
    },
    playerRadius: {
        overflow: 'hidden',
        borderRadius: spacing.s1,
    },
    linearGradientWeb: {
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        backgroundImage: 'linear-gradient(to right,rgba(0, 0, 0, 0.5) 45%, rgba(0, 0, 0, 0) 85% )',
        borderRadius: spacing.s1
    },
    linearGradientForContentHeroCarusel: {
        backgroundImage: contentHeroCaruselGradint
    }
});

export default WithTouchableItemBase(TouchableItem);
