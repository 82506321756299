import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { css, StyleSheet } from 'aphrodite-jss';
import { withRouter } from 'react-router-dom';
import { layoutStyle, baseColors, containerStyle, importantClass } from '../../../../styles';
import { CREATE_INPUTS } from '../../constants';
import { components as Core, constants as coreConstants, ROUTES } from '../../../core';
import WithEventCreateBase, { styles as baseStyles, EVENT_FIELDS } from './CreateEventBase';
import ModeratorOptions from './ModeratorOptions';

const ROW_NUMBER = 4;

class CreateEvent extends PureComponent {
    static propTypes = {
        isLoading: PropTypes.bool.isRequired,
        startDate: PropTypes.object.isRequired,
        onChangeText: PropTypes.func.isRequired,
        submitData: PropTypes.func.isRequired,
        startPickersData: PropTypes.object.isRequired,
        startPickersTimeData: PropTypes.object.isRequired,
        endPickersTimeData: PropTypes.object.isRequired,
        onUploadPhoto: PropTypes.func.isRequired,
        i18n: PropTypes.object.isRequired,
        isUserRole: PropTypes.bool,
        name: PropTypes.string,
        description: PropTypes.string,
        location: PropTypes.string,
        formDataImage: PropTypes.string,
        privacy: PropTypes.string,
        startPickersValue: PropTypes.object.isRequired,
        startPickersTimeValue: PropTypes.object,
        endPickersTimeValue: PropTypes.object,
        isEditing: PropTypes.bool,
        title: PropTypes.string.isRequired,
        buttonLabel: PropTypes.string.isRequired,
        errors: PropTypes.object,
        history: PropTypes.object.isRequired,
        createdEventId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        id: PropTypes.number,
        moderatorFieldsData: PropTypes.object.isRequired,
        isCreating: PropTypes.bool,
        entityType: PropTypes.string,
        close: PropTypes.func.isRequired,
        makePublic: PropTypes.func.isRequired,
        edited: PropTypes.bool,
        hasDeleteOption: PropTypes.bool.isRequired,
        isAdminCreated: PropTypes.bool,
        isEditingEvent: PropTypes.bool,
    };

    static defaultProps = {
        errors: {},
        name: null,
        isEditing: false,
        isUserRole: true,
        description: null,
        location: null,
        formDataImage: null,
        privacy: null,
        startPickersTimeValue: null,
        endPickersTimeValue: null,
        createdEventId: null,
        id: null,
        isCreating: false,
        entityType: null,
        edited: false,
        isAdminCreated: false,
        isEditingEvent: false
    };

    constructor(props) {
        super(props);
        this.propsToPass = {
            onPress: this.props.submitData
        };
    }

    get cancelModalData() {
        const { i18n, id } = this.props;
        const actionType = id ? i18n.t('createEntity.action.editing') : i18n.t('createEntity.action.creating');
        const entity = i18n.t('createEntity.type.event');
        return {
            title: i18n.t('createEntity.cancel.title', { entity, actionType }),
            text: i18n.t('createEntity.cancel.message', { entity, actionType })
        };
    }

    componentDidUpdate(prevProps) {
        if (!this.props.isCreating && prevProps.isCreating) {
            this.props.close();
            this.props.history.push(ROUTES.eventDetails(this.props.createdEventId));
        }

        if (!this.props.isEditing && prevProps.isEditing) {
            this.props.close();
        }
    }

    get renderDatePickersGroupStart() {
        const title = this.props.i18n.t('start_on');
        const min = this.props.startDate;

        return this.renderDatePickersGroupStartElements(
            this.props.startPickersData,
            this.props.startPickersValue,
            title,
            min,
            this.props.errors.errorStartDate
        );
    }

    get renderDatePickersGroupEnd() {
        const title = this.props.i18n.t('start_end_time');

        return this.renderDatePickersGroupEndElements(
            this.props.startPickersTimeData,
            this.props.endPickersTimeData,
            this.props.startPickersTimeValue,
            this.props.endPickersTimeValue,
            title,
            this.props.startPickersValue,
            this.props.errors.errorStartTime,
            this.props.errors.errorEndTime
        );
    }

    renderDatePickers() {
        return (
            <div>
                {this.renderDatePickersGroupStart}
                {this.renderDatePickersGroupEnd}
            </div>
        );
    }

    renderDatePickersGroupStartElements(pickersData, value, title, min, error) {
        return (
            <div className={css(styles.datePickerWrapper)}>
                <div className={css(styles.title)}>{title}</div>
                <div className={css(styles.datePickerWide)}>
                    <Core.DateField
                        id="eventDateInput"
                        value={value}
                        onChange={pickersData.eventHandler}
                        label={pickersData.fieldNameDate}
                        minDate={min}
                        customType="date"
                        dateFormat={coreConstants.DATE_FORMATS.monthFullDayYearFull}
                        error={!!error}
                        helperText={error}
                        disabled={false}
                        fullWidth={true}
                    />
                </div>
            </div>
        );
    }

    renderDatePickersGroupEndElements(startPickersData, endPickersData, startValue, endValue, title, min, errorStart, errorEnd) {
        return (
            <div className={css(styles.datePickerWrapper)}>
                <div className={css(styles.title)}>{title}</div>
                <div className={css(layoutStyle.flex)}>
                    <div className={css(layoutStyle.twoButtonsLeft)}>
                        <Core.Input
                            id="eventStartTimeInput"
                            customType="time"
                            label={startPickersData.fieldNameTime}
                            value={startValue}
                            onChange={startPickersData.eventHandler}
                            fullWidth={true}
                            dateFormat={coreConstants.DATE_FORMATS.twelveHoursTime}
                            error={!!errorStart}
                            helperText={errorStart}
                        />
                    </div>
                    <div className={css(layoutStyle.twoButtonsRight)}>
                        <Core.Input
                            id="eventEndTimeInput"
                            customType="time"
                            label={endPickersData.fieldNameTime}
                            value={endValue}
                            onChange={endPickersData.eventHandler}
                            fullWidth={true}
                            dateFormat={coreConstants.DATE_FORMATS.twelveHoursTime}
                            error={!!errorEnd}
                            helperText={errorEnd}
                        />
                    </div>
                </div>
            </div>
        );
    }

    renderInputs() {
        const { name, errors, i18n } = this.props;
        return (
            <div className={css(styles.dataContainer)}>
                <Core.Input
                    id="eventNameInput"
                    inputProps={{ maxLength: coreConstants.CREATE_ENTITY_MAX_NAME_LEN }}
                    label={i18n.t(EVENT_FIELDS.eventName)}
                    autoFocus={true}
                    onChangeText={this.props.onChangeText(CREATE_INPUTS.name)}
                    value={name}
                    error={!!errors.errorName}
                    helperText={errors.errorName}
                    fullWidth={true}
                />
            </div>
        );
    }

    renderBottomInputs() {
        const { description, location, i18n } = this.props;
        return (
            <div className={css(styles.dataContainer)}>
                <div className={css(styles.title)}>{i18n.t('location_info')}</div>
                <Core.Input
                    id="eventLocationInput"
                    label={i18n.t(EVENT_FIELDS.location)}
                    autoCorrect={false}
                    onChangeText={this.props.onChangeText(CREATE_INPUTS.location)}
                    value={location}
                    fullWidth={true}
                />
                <Core.Input
                    id="eventDescriptionInput"
                    label={i18n.t(EVENT_FIELDS.whatEvent)}
                    autoCorrect={false}
                    onChangeText={this.props.onChangeText(CREATE_INPUTS.description)}
                    value={description}
                    multiline={true}
                    rows={ROW_NUMBER}
                    fullWidth={true}
                />
            </div>
        );
    }

    getSingleStepContent = () => {
        const { formDataImage, isLoading, onUploadPhoto, entityType, makePublic, isUserRole, moderatorFieldsData,
            privacy, hasDeleteOption, isEditingEvent, isAdminCreated } = this.props;
        return isLoading ? <Core.BlockingLoading isLoading={isLoading} /> : (
            <React.Fragment>
                <Core.ImageUploader
                    picture={formDataImage}
                    fileChanged={onUploadPhoto}
                    className={css(layoutStyle.noPadding, styles.subHeaderStyle)}
                    titleClassName={css(styles.modalTitle)}
                    entityType={entityType}
                    hasDeleteOption={hasDeleteOption}
                    isEditing={isEditingEvent}
                    isAdminCreated={isAdminCreated}
                />
                <div className={css(containerStyle.container)}>
                    {this.renderInputs()}
                    {this.renderDatePickers()}
                    {this.renderBottomInputs()}
                    {!isUserRole ? (
                        <ModeratorOptions
                            {...moderatorFieldsData}
                        />
                    )
                        : null
                    }
                    <Core.PublicVisibilitySwitch
                        id="eventPublicVisibilitySwitch"
                        isPublic={privacy}
                        entityType={coreConstants.ENTITIES.event}
                        makePublic={makePublic}
                    />
                </div>
            </React.Fragment>
        );
    };

    cancelFlow = () => this.props.close();

    render() {
        const { title, edited, buttonLabel } = this.props;
        return (
            <Core.Steps
                propsToPass={this.propsToPass}
                cancelFlow={this.cancelFlow}
                title={title}
                isCloseButtonShown={true}
                isSingleStep={true}
                cancelModalData={this.cancelModalData}
                edited={edited}
                needTrackChanges={true}
                modalWithScrollbar={true}
                buttonLabel={buttonLabel}>
                {this.getSingleStepContent}
            </Core.Steps>
        );
    }
}


export default withRouter(WithEventCreateBase(CreateEvent));

const styles = StyleSheet.create({
    ...baseStyles,
    subHeaderStyle: {
        ...baseStyles.subHeaderStyle,
        backgroundColor: baseColors.white,
        width: '100%'
    },
    modalTitle: importantClass({
        color: baseColors.black,
        ...baseStyles.title
    })
});
