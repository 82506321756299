import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import { css, StyleSheet } from 'aphrodite-jss';
import { appFonts, baseColors, layoutStyle, spacing, important } from '../../../../styles';
import { ListItem } from '../List';
import WithPublicVisibilitySwitchBase from './PublicVisibilitySwitchBase';
import Switch from '../Switch';
import Modal from '../Modal.web';
import InfoModal from '../InfoModal';

class PublicVisibilitySwitch extends PureComponent {
    static propTypes = {
        makePublic: PropTypes.func.isRequired,
        isPublic: PropTypes.number.isRequired,
        title: PropTypes.string.isRequired,
        subtitle: PropTypes.string.isRequired,
        content: PropTypes.shape({
            text: PropTypes.string,
            leftLabel: PropTypes.string,
            title: PropTypes.string,
            subtitle: PropTypes.string,
            rightLabel: PropTypes.string
        }).isRequired,
        id: PropTypes.string
    };

    static defaultProps = {
        id: undefined
    }

    changeSetting = () => {
        const { content, isPublic } = this.props;
        if (isPublic) {
            this.props.makePublic(); // toggle to private
            return;
        }
        this.closeModal = Modal.open(InfoModal,
            {
                iconName: 'question',
                text: content.text,
                isButtonVisible: false,
                buttons: [
                    { text: content.leftLabel, onPress: this.onConfirmPrivacyChange, isBoldText: true },
                    { text: content.rightLabel, onPress: this.onCancelPrivacyChange },
                ],
            },
            {
                isTransparent: true,
                fadeTransition: true,
                PaperProps: {
                    elevation: 0
                }
            }
        );
    }

    onCancelPrivacyChange = () => {
        this.closeModal();
    };

    onConfirmPrivacyChange = () => {
        this.closeModal();
        this.props.makePublic();
    };


    render() {
        const { isPublic, content, id } = this.props;
        return (
            <ListItem
                classes={{ root: css(styles.listItem, layoutStyle.fw) }}>
                <div className={css(layoutStyle.flexColumn)}>
                    <span className={css(styles.itemTitle)}>{content.title}</span>
                    <span className={css(styles.itemSubtitle)}>{content.subtitle}</span>
                </div>
                <Switch
                    id={id}
                    value={isPublic}
                    onChange={this.changeSetting}
                />
            </ListItem>
        );
    }
}

const styles = StyleSheet.create({
    listItem: {
        padding: important(0),
        margin: `${spacing.s2}px 0`,
        justifyContent: important('space-between')
    },
    itemSubtitle: {
        ...appFonts.smRegular,
        color: baseColors.grey40,
        marginLeft: 0,
        padding: 0
    },
    itemTitle: {
        ...appFonts.lgMedium,
        color: baseColors.black,
        marginLeft: 0,
    },
});

export default WithPublicVisibilitySwitchBase(PublicVisibilitySwitch);

