import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import Dialog from '@material-ui/core/Dialog';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import ListSubheader from '@material-ui/core/ListSubheader';
import Popover from '@material-ui/core/Popover';
import Fade from '@material-ui/core/Fade';
import classnames from 'classnames';
import { css, StyleSheet } from 'aphrodite-jss';
import { baseColors, importantClass, layoutStyle, spacing } from '../../../../styles';
import { components as Core } from '../..';

const ActionSheet = { open: null };
export default ActionSheet;

export class ActionSheetComponent extends PureComponent {
    static propTypes = {
        title: PropTypes.string,
        options: PropTypes.arrayOf(PropTypes.shape({
            title: PropTypes.string.isRequired,
            onPress: PropTypes.func.isRequired,
            icon: PropTypes.element
        })),
        onPress: PropTypes.func,
        destructiveButtonIndex: PropTypes.number
    };

    static defaultProps = {
        destructiveButtonIndex: undefined,
        onPress: undefined,
        options: undefined,
        title: undefined,
    };

    constructor(props) {
        super(props);
        this.state = { isOpen: false, isSmallerOptionsWidth: false };
    }

    componentDidMount() {
        ActionSheet.open = this.open;
        window.onpopstate = this.close;
    }

    onPress = index => {
        this.close();
        if (this.props.onPress) this.props.onPress(index);
        if (_.has(this.options, [index, 'onPress'])) this.options[index].onPress();
    };

    get options() {
        return this.state.options || this.props.options;
    }

    get title() {
        return this.state.title || this.props.title;
    }

    get destructiveButtonIndex() {
        return this.state.destructiveButtonIndex || this.props.destructiveButtonIndex;
    }

    open = (options, destructiveButtonIndex, title, event, isSmallerOptionsWidth = false) => {
        this.setState({ options, destructiveButtonIndex, isOpen: true, title, anchorEl: _.get(event, 'target'), isSmallerOptionsWidth });
    };

    close = () => this.setState({ destructiveButtonIndex: null, isOpen: false, anchorEl: null });

    get titleComponent() {
        const { anchorEl } = this.state;
        return this.title ? (
            <ListSubheader classes={{ root: css(anchorEl ? layoutStyle.textLeft : layoutStyle.textCenter) }}>
                {this.title}
            </ListSubheader>
        ) : null;
    }

    get optionsComponent() {
        const { anchorEl } = this.state;
        return this.options ? (
            <List>
                {_.map(this.options, (item, index) => (
                    <ListItem
                        id={_.camelCase(`${item.title}Btn`)}
                        button={true}
                        key={item.title}
                        onClick={() => this.onPress(index)}
                        classes={{ root: css(this.state.isSmallerOptionsWidth ? styles.smallerListItem : styles.listItem, anchorEl ? layoutStyle.textLeft : layoutStyle.textCenter, item.isDisabled ? styles.lockedItemHover : {}) }}
                        divider={item.underlined}>
                        {item.icon ? (
                            <ListItemIcon>
                                {item.icon}
                            </ListItemIcon>
                        ) : null}
                        <ListItemText
                            primary={(
                                <span className={classnames({
                                    [css(layoutStyle.danger)]: this.state.destructiveButtonIndex === index,
                                    [css(styles.lockedItem)]: item.isDisabled
                                })}>
                                    {item.title}
                                    {item.isDisabled && (
                                        <Core.Icon size={spacing.s3} color={baseColors.grey60} name="lock" type="light" className={css(styles.lockIcon)} />
                                    )}
                                </span>
                            )}
                            classes={{ root: css(layoutStyle.noMargin) }}
                        />
                    </ListItem>
                ))}
            </List>
        ) : null;
    }

    get content() {
        return (
            <div
                tabIndex="0"
                role="button"
                onClick={this.close}
                onKeyDown={this.close}>
                {this.titleComponent}
                {this.optionsComponent}
            </div>
        );
    }

    render() {
        return this.state.anchorEl ? (
            <Popover
                anchorEl={this.state.anchorEl}
                open={this.state.isOpen}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                TransitionComponent={Fade}
                onClose={this.close}>
                {this.content}
            </Popover>
        )
            : (
                <Dialog
                    open={this.state.isOpen}
                    onClose={this.close}>
                    {this.content}
                </Dialog>
            );
    }
}

const MIN_OPTION_WIDTH = 300;
const SMALLER_OPTIONS_WIDTH = 160;

const styles = StyleSheet.create({
    listItem: { minWidth: MIN_OPTION_WIDTH },
    smallerListItem: { minWidth: SMALLER_OPTIONS_WIDTH },
    lockedItem: { color: baseColors.grey60 },
    lockIcon: { marginLeft: spacing.s2 },
    lockedItemHover: importantClass({ cursor: 'not-allowed' })
});