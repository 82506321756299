import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { StyleSheet, css } from 'aphrodite-jss';

import WithSettingsBase, { styles as baseStyles } from './SettingsBase';
import { baseColors, importantClass, spacing } from '../../../../styles';
import { ActionSheet, AsyncComponent, components as Core, Modal, openUrl, getFullAppVersion, Storage, Platform, PLATFORMS, ENTITIES_ACTIVE_TAB } from '../../../core';
import { ICONS, LIST_ITEM_TYPES } from '../../constants';
import { constants as settingsConstants } from '../../index';
import NotificationsSettings from '../NotificationsSettings';
import PrivacySettings from '../PrivacySettings';
import SmartModeSettings from '../SmartModeSettings';
import { components as Invitations } from '../../../invitations';
import { components as AuthComponents } from '../../../auth';
import AccountSettings from '../AccountSettings';
import { FamilyMembers } from '../index';

class Settings extends Component {
    static propTypes = {
        isSigningOut: PropTypes.bool,
        isShowingProgressIndicator: PropTypes.bool,
        logout: PropTypes.func,
        i18n: PropTypes.object.isRequired,
        isFriendsAndFamilyEnabled: PropTypes.bool.isRequired,
        toggleRememberMe: PropTypes.func.isRequired,
        currentUserPrivacySettings: PropTypes.array,
        rememberMe: PropTypes.bool.isRequired,
        title: PropTypes.string.isRequired,
        termsOfService: PropTypes.string,
        isSmartModeOptionShown: PropTypes.bool.isRequired,
        isSso: PropTypes.bool.isRequired
    };

    static defaultProps = {
        logout: null,
        isSigningOut: false,
        isShowingProgressIndicator: false,
        currentUserPrivacySettings: [],
        termsOfService: undefined
    };


    constructor(props) {
        super(props);
        const { i18n, userHasDependentRole } = props;
        this.tabs = [
            {
                id: settingsConstants.SETTINGS_TABS.account,
                label: i18n.t('account'),
                icon: this.generateIcon(ICONS.user),
                action: this.openAccountSettings
            },
            {
                id: settingsConstants.SETTINGS_TABS.notifications,
                label: i18n.t('notificationsSettingsTitle'),
                icon: this.generateIcon(ICONS.bell),
                action: this.openNotificationSettings
            }
        ];
        if (this.props.currentUserPrivacySettings.length) {
            this.tabs.push(
                {
                    id: settingsConstants.SETTINGS_TABS.privacy,
                    label: i18n.t('privacySettingsTitle'),
                    icon: this.generateIcon(ICONS.privacy),
                    action: this.openPrivacySettings,
                });

            if (!this.props.isSso) {
                this.tabs.push(
                    {
                        id: settingsConstants.SETTINGS_TABS.changePassword,
                        label: i18n.t('changePasswordTitle'),
                        icon: this.generateIcon(ICONS.password),
                        action: this.openChangePassword
                    });
            }
        }
        if (this.props.isFriendsAndFamilyEnabled) {
            this.tabs.push(
                {
                    id: settingsConstants.SETTINGS_TABS.findFriendsAndFamily,
                    label: i18n.t('invitation.findFriendsAndFamily'),
                    icon: this.generateIcon(ICONS.userPlus),
                    action: this.onFindFriends
                });
        }
        if (this.props.isSmartModeOptionShown) {
            this.tabs.push(
                {
                    id: settingsConstants.SETTINGS_TABS.goalSmartMode,
                    label: i18n.t('goalSmartMode.title'),
                    icon: this.generateIcon(ICONS.bolt),
                    action: this.onGoalSmartMode
                }
            );
        }
        if (this.props.isFamilyInviteEnabled && !userHasDependentRole) {
            this.tabs.push(
                {
                    id: settingsConstants.SETTINGS_TABS.familyMembers,
                    label: i18n.t('familyMembers'),
                    icon: this.generateIcon(ICONS.userFriends),
                    action: this.openFamilyMembers
                }
            );
        }

        this.state = {
            activeTab: this.tabs[0]
        };
    }

    async componentDidMount() {
        if (Platform.OS === PLATFORMS.web) {
            const activeTab = await Storage.getItem(ENTITIES_ACTIVE_TAB.SETTINGS);
            if (activeTab) {
                this.setState({ activeTab });
            }
        }
    }


    onPressHelpOption = option => openUrl(option.url);

    onGetHelpPress = option => (
        option.type === LIST_ITEM_TYPES.faq ?
            this.onPressHelpOption(option) :
            this.onPressFeedbackOption(option)
    );

    onPressFeedbackOption = option => {
        Modal.open(AsyncComponent(() => import('../Feedback')), { option, isPopup: true }, {
            cancelable: true,
            isNoPadding: false,
            isContainer: false,
            isFullHeight: false
        });
    };

    onPressLegalOption = legalType => {
        if (legalType === LIST_ITEM_TYPES.terms && this.props.termsOfService) {
            openUrl(this.props.termsOfService);
        } else {
            Modal.open(AsyncComponent(() => import('../Legals')), { legalType, isPopup: true, isModal: true }, {
                cancelable: true,
                isNoPadding: true,
                isContainer: true,
                isFullHeight: true,
                isNoPaddingHorizontal: true,
                isNoWidthLimit: true,
                maxWidth: 600,
                isMaxHeight: true
            });
        }
    };


    get rightIcon() {
        return (
            <Core.Icon
                type="fa"
                name="angle-right"
                color={baseColors.grey50}
            />
        );
    }

    renderListOfActions = (actions, title) => (
        <>
            <Core.SubHeader
                title={title}
                noTopPadding={true}
            />
            <Core.List>
                {actions.map(item => (
                    <>
                        <Core.ListItem button={true} onClick={item.onPress}>
                            <Core.ListItemText primary={item.title} />
                            {this.rightIcon}
                        </Core.ListItem>
                        <Core.Divider light={true} />
                    </>
                ))
                }
            </Core.List>
        </>
    );

    showSelectedTab() {
        const { activeTab } = this.state;
        if (activeTab.id === settingsConstants.SETTINGS_TABS.account) return <AccountSettings />;
        if (activeTab.id === settingsConstants.SETTINGS_TABS.notifications) return <NotificationsSettings />;
        if (activeTab.id === settingsConstants.SETTINGS_TABS.privacy) return <PrivacySettings isPage={false} />;
        if (activeTab.id === settingsConstants.SETTINGS_TABS.findFriendsAndFamily) return <Invitations.FindFriendsAndFamily isOpenedFromSettings={true} />;
        if (activeTab.id === settingsConstants.SETTINGS_TABS.changePassword) return <AuthComponents.ChangePassword />;
        if (activeTab.id === settingsConstants.SETTINGS_TABS.linkAccounts) return <AuthComponents.LinkAccounts />;
        if (activeTab.id === settingsConstants.SETTINGS_TABS.goalSmartMode) return <SmartModeSettings />;
        if (activeTab.id === settingsConstants.SETTINGS_TABS.familyMembers) return <FamilyMembers />;
        return <NotificationsSettings />;
    }

    generateIcon(name) {
        return <Core.Icon type="fa" name={name} size={spacing.s3} />;
    }

    onSignOut = () => this.props.logout();

    openOptions = options => ActionSheet.open(options);

    get getComponentsAfterTabs() {
        const { i18n, isSigningOut } = this.props;
        return (
            <div className={css(styles.afterTabs)}>
                <span className={css(styles.version)}>v {getFullAppVersion()}</span>
                <div className={css(styles.switchWrapper)}>
                    <div>
                        <div className={css(styles.privateTitle)}>{i18n.t('keep_me_signed_in')}</div>
                    </div>
                    <Core.Switch id="keepMeSignedIn" value={this.props.rememberMe} onChange={this.props.toggleRememberMe} />
                </div>
                <Core.Button
                    id="signOutBtn"
                    color="secondary"
                    type="text"
                    fullWidth={true}
                    onPress={this.onSignOut}
                    disabled={isSigningOut}
                    isLoading={isSigningOut}
                    classes={{ root: css(styles.button), label: css(styles.buttonContent) }}>
                    <Core.Icon
                        name="sign-out"
                        type="fa"
                        size={spacing.s4}
                    />
                    <span className={css(styles.settingsLabel)}>{i18n.t('signOut')}</span>
                </Core.Button>
            </div>
        );
    }

    onTabsChange = activeTab => {
        this.setState({ activeTab });


        if (Platform.OS === PLATFORMS.web) {
            Storage.setItem(ENTITIES_ACTIVE_TAB.SETTINGS, activeTab);
        }
    };

    render() {
        const { title } = this.props;
        return (
            <Core.Layout.WiderContainer>
                <Core.EntityDetailsHeader
                    hasBackButton={true}
                />
                <Core.SubHeader
                    title={title}
                    noTopPadding={true}
                />
                <Core.TabsWithContent
                    barStyles={styles.stickyBar}
                    onChange={this.onTabsChange}
                    tabs={this.tabs}
                    activeTab={this.state.activeTab}
                    isStickyUnderNavBar={true}
                    afterTabsComponent={this.getComponentsAfterTabs}>
                    <div className={css(styles.tabContentContainer, styles.contentContainer)}>
                        <Core.BlockingLoading isLoading={this.props.isShowingProgressIndicator} />
                        {this.showSelectedTab()}
                    </div>
                </Core.TabsWithContent>
            </Core.Layout.WiderContainer>
        );
    }
}

const styles = StyleSheet.create({
    ...baseStyles,
    button: importantClass({
        color: baseColors.dangerDarker,
    }),
    buttonContent: {
        justifyContent: 'flex-start !important'
    },
    settingsLabel: {
        marginLeft: spacing.s2
    },
    switchWrapper: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        padding: spacing.s1,
    },
    afterTabs: {
        marginTop: spacing.s2
    },
    version: {
        marginLeft: spacing.s1,
        color: baseColors.grey50
    },
    contentContainer: {
        paddingLeft: spacing.s3,
        paddingRight: spacing.s3
    }
});

export default WithSettingsBase(Settings);
