import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { css, StyleSheet } from 'aphrodite-jss';
import _ from 'lodash';
import { baseColors, layoutStyle } from '../../../../../styles';
import WithCreateEntityListItemBase, { styles as baseStyles, LEFT_ICON_SIZE } from './ListItemBase';
import { ListItem, ListItemText } from '../../List';
import Icon from '../../Icon';

class CreateEntityListItem extends PureComponent {
    static propTypes = {
        item: PropTypes.object.isRequired,
        iconName: PropTypes.string,
        onPress: PropTypes.func.isRequired,
        isActive: PropTypes.bool.isRequired,
        size: PropTypes.number.isRequired,
        isSmall: PropTypes.bool.isRequired,
        id: PropTypes.string
    };

    static defaultProps = {
        iconName: undefined,
        id: undefined
    };

    getLeftIcon = item => {
        const { leftIcon, leftIconSize, leftIconColor } = item;
        return !_.isString(leftIcon) ? leftIcon : (
            <Icon
                type="fa"
                name={leftIcon}
                size={leftIconSize || LEFT_ICON_SIZE}
                color={leftIconColor || baseColors.primary}
            />
        );
    };

    onPress = () => {
        this.props.onPress && this.props.onPress(this.props.item);
    };

    get icon() {
        const { iconName, size } = this.props;
        return iconName === 'empty' ? null :
            <Icon type="fa" name={iconName || 'chevron-right'} size={size} color={baseColors.grey50} />;
    }

    render() {
        const { item, isSmall, id } = this.props;
        return !isSmall ? (
            <ListItem
                id={id}
                button={true}
                className={css(layoutStyle.listItemBig)}
                onClick={this.onPress}>
                <div className={css(styles.leftIconContainer)}>
                    {this.getLeftIcon(item)}
                </div>
                <ListItemText
                    primary={(
                        <span className={css(styles.itemTitle, this.props.isActive ? styles.itemTitleActive : null)}>
                            {item.goalName || item.name}
                        </span>
                    )}
                    secondary={item.description}
                />
                {this.icon}
            </ListItem>
        ) : (
            <ListItem
                id={id}
                button={true}
                className={css(layoutStyle.listItem)}
                onClick={this.onPress}>
                <div className={css(styles.leftIconContainer)}>
                    {this.getLeftIcon(item)}
                </div>
                <ListItemText
                    primary={(
                        <span className={css(styles.itemTitle, this.props.isActive ? styles.itemTitleActive : null)}>
                            {item.goalName || item.name}
                        </span>
                    )}
                    secondary={item.description}
                />
                {this.icon}
            </ListItem>
        );
    }
}

export default WithCreateEntityListItemBase(CreateEntityListItem);

const styles = StyleSheet.create(baseStyles);

