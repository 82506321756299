import axios from 'axios';
import { getBaseUrl, USE_V2_API } from '../core';
import systemBuildVariant, { BUILDS } from '../../config/buildVariant';

const isLiveBetter = systemBuildVariant === BUILDS.mylivebetter;

export function getContent(parameters, isSearching = false) {
    const params = { offset: 0, maxCount: 10, ...parameters };
    if (isLiveBetter || isSearching) {
        return axios.get(getBaseUrl('content_links/content', USE_V2_API), { params });
    } else {
        return axios.get(getBaseUrl('recommended_contents'), { params });
    }
}

export function getContentTags() {
    return axios.get(getBaseUrl('content_links/content_tags'));
}

export function getContentItem(id) {
    return axios.get(getBaseUrl(`content_links/content/${id}`, USE_V2_API));
}

export function bookmarkContent(id) {
    return axios.put(getBaseUrl(`content_links_bookmarked/${id}`));
}

export function unmarkContent(id) {
    return axios.delete(getBaseUrl(`content_links_bookmarked/${id}`));
}

export function getSavedContent() {
    return axios.get(getBaseUrl('content_links_bookmarked'));
}

export function getContentTracking(id) {
    return axios.post(getBaseUrl(`content_links/track/${id}`), {});
}

export function getContentTrackingOpened(id) {
    return axios.post(getBaseUrl(`content_links/track_open/${id}`), {});
}

export function updateRecommendedContent() {
    return axios.post(getBaseUrl('recommended_contents'), {});
}

export function getRecommendedContent(parameters) {
    const params = { offset: 0, maxCount: 10, ...parameters };
    return axios.get(getBaseUrl('recommended_contents'), { params });
}