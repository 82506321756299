import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import classnames from 'classnames';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import { StyleSheet, css } from 'aphrodite-jss';

import { isEdge } from 'react-device-detect';
import { appFonts, baseColors, layoutStyle, spacing, importantStyles, inputButtonSize, importantClass } from '../../../../styles';
import Icon, { IconButton } from '../Icon';
import Button from '../Button';
import Alert from '../Alert';
import TimePicker from '../TimePicker';
import translate from '../../services/translate';

const CUSTOM_TYPES = {
    password: 'password',
    units: 'units',
    date: 'date',
    time: 'time',
    search: 'search'
};

const TYPES = {
    base: 'base',
    ...CUSTOM_TYPES
};

class Input extends PureComponent {
    static propTypes = {
        customType: PropTypes.oneOf(_.values(CUSTOM_TYPES)),
        onPressUnits: PropTypes.func,
        openDatePicker: PropTypes.func,
        unit: PropTypes.string,
        unitOptions: PropTypes.array,
        unitDisabled: PropTypes.bool,
        icon: PropTypes.element,
        margin: PropTypes.string,
        label: PropTypes.string,
        disabled: PropTypes.bool,
        InputProps: PropTypes.object,
        hintText: PropTypes.string,
        onChange: PropTypes.func,
        onChangeText: PropTypes.func,
        helperText: PropTypes.string,
        multiline: PropTypes.bool,
        isDisabledTextOverflow: PropTypes.bool,
        inputClassName: PropTypes.bool,
        onPressEnter: PropTypes.func,
        isCustomizedSearchBar: PropTypes.bool,
        textFieldClassName: PropTypes.string,
        labelInvisible: PropTypes.bool,
        placeholder: PropTypes.string,
        onFocus: PropTypes.func,
        onBlur: PropTypes.func,
        isSmallActiveLabel: PropTypes.bool
    };

    static defaultProps = {
        customType: undefined,
        onPressUnits: undefined,
        openDatePicker: undefined,
        helperText: null,
        unit: undefined,
        unitDisabled: false,
        unitOptions: [],
        icon: undefined,
        disabled: false,
        margin: 'normal',
        label: '',
        InputProps: {},
        hintText: undefined,
        onChange: undefined,
        onChangeText: undefined,
        multiline: false,
        isDisabledTextOverflow: false,
        inputClassName: null,
        onPressEnter: null,
        isCustomizedSearchBar: false,
        labelInvisible: false,
        textFieldClassName: '',
        placeholder: '',
        onFocus: null,
        onBlur: null,
        isSmallActiveLabel: false
    };

    constructor(props) {
        super(props);
        this.state = { showPassword: false };
    }

    onChange = event => {
        this.props.onChange && this.props.onChange(event);
        this.props.onChangeText && this.props.onChangeText(event.target.value);
    };

    onKeyPress = event => {
        if (event.key === 'Enter') {
            this.props.onPressEnter();
        }
    }

    onIconClickPassword = () => {
        this.setState(state => ({ showPassword: !state.showPassword }), this.setCaretPosition);
    };

    openHint = () => {
        const { hintText, i18n } = this.props;
        return Alert.alert(null, hintText, [{ text: i18n.t('ok'), isPrimary: false }]);
    };

    get type() {
        return this.props.customType;
    }

    setRef = ref => this.passwordRef = ref;

    get allProps() {
        const { disabled, unitDisabled, onPressUnits, unit, openDatePicker, InputProps, icon, isDisabledTextOverflow,
            inputClassName, isCustomizedSearchBar, labelInvisible, textFieldClassName, placeholder, isLibraryPage, noTopMargin, unitsRegularFont, isSmallActiveLabel, label, id } = this.props;
        const textOverflow = isDisabledTextOverflow ? null: layoutStyle.textOverflow;
        const isSearch = this.type === 'search';
        return {
            [TYPES.password]: {
                type: this.state.showPassword ? 'text' : 'password',
                InputProps: {
                    endAdornment: (
                        <InputAdornment position="end" classes={{ root: css(styles.adornment) }}>
                            {!isEdge ? (
                                <IconButton
                                    type="fa"
                                    disabled={disabled}
                                    onClick={this.onIconClickPassword}
                                    name={this.state.showPassword ? 'eye-slash' : 'eye'}
                                    size={inputButtonSize}
                                    isSmall={true}
                                    className={css(styles.adornmentIconButton)}
                                />
                            ) : null}
                        </InputAdornment>
                    )
                }
            },
            [TYPES.units]: {
                InputProps: {
                    endAdornment: (
                        <InputAdornment position="end" classes={{ root: css(styles.adornment) }}>
                            <Button
                                id={`${label}/${unit}/UnitButton`}
                                size="xsmall"
                                className={css(
                                    styles.units, styles.adornmentIcon,
                                    unitDisabled && styles.adornmentIconDisabled,
                                    !unitDisabled && styles.unitsNotDisabled,
                                    unitsRegularFont && styles.unitsRegularFont)}
                                onPress={onPressUnits}
                                disabled={unitDisabled}
                                type="outlined">
                                {unit}
                            </Button>
                        </InputAdornment>
                    ),
                    classes: {
                        input: !InputProps.disableUnderline && css(styles.input, inputClassName, layoutStyle.flex1)
                    },
                },
            },
            [TYPES.date]: {
                InputProps: {
                    endAdornment: (
                        <InputAdornment id={id} position="end" classes={{ root: css(styles.adornment) }}>
                            <IconButton
                                id="calendarButton"
                                type="fa"
                                name="calendar"
                                size={inputButtonSize}
                                isSmall={false}
                                onClick={openDatePicker}
                                color="secondary"
                                className={css(styles.adornmentIconButton)}
                            />
                        </InputAdornment>
                    )
                }
            },
            [TYPES.time]: {
                InputProps: {
                    endAdornment: (
                        <InputAdornment position="end" classes={{ root: css(styles.adornment) }}>
                            <IconButton
                                type="fa"
                                name="clock"
                                size={inputButtonSize}
                                isSmall={true}
                                onClick={openDatePicker}
                                color="secondary"
                                className={css(styles.adornmentIconButton)}
                            />
                        </InputAdornment>
                    )
                }
            },
            [TYPES.search]: {
                InputProps: {
                    startAdornment: (
                        <InputAdornment position="end" classes={{ root: css(styles.adornment) }}>
                            {!isCustomizedSearchBar && (
                                <Icon
                                    size={inputButtonSize}
                                    className={css(styles.adornmentIconSearch)}
                                    type="fa"
                                    disabled={disabled}
                                    name="search"
                                    color={baseColors.grey40}
                                />
                            )
                            }
                        </InputAdornment>
                    ),
                    placeholder
                }
            },
            base: {
                type: 'text',
                InputProps: {
                    classes: {
                        formControl: classnames(
                            !InputProps.disableUnderline &&
                            css(!isLibraryPage && styles.textField,
                                isSearch && styles.textFieldSearchPadding,
                                noTopMargin && styles.noTopMargin,
                                disabled && styles.disabledTextField),
                            textFieldClassName),
                        underline: css(styles.underline),
                        focused: css(styles.inputFocused),
                        input: !InputProps.disableUnderline && css(
                            isCustomizedSearchBar ? styles.customizedInput : styles.input,
                            isSearch && styles.inputSearch,
                            inputClassName,
                        ),
                    },
                    endAdornment: icon ? (
                        <InputAdornment position="end" classes={{ root: css(styles.adornment) }}>
                            {icon}
                        </InputAdornment>
                    ) : undefined
                },
                InputLabelProps: {
                    FormLabelClasses: {
                        focused: css(textOverflow, styles.labelFocused),
                    },
                    classes: {
                        //Do not add the condition to check the label width it's breaks the initial transition
                        //and the component will work correctly only after page reload
                        root: css(
                            textOverflow,
                            styles.label,
                            isCustomizedSearchBar && styles.customizedLabel,
                            labelInvisible && layoutStyle.opacityNone,
                        ),
                        // focused: css(styles.labelFocused),
                        animated: css(styles.labelAnimated),
                        shrink: !isCustomizedSearchBar && css(styles.labelShrink, isSmallActiveLabel ? styles.labelShrinkSmall : null),
                        disabled: css(styles.disabledLabel)
                    },
                },
                FormHelperTextProps: {
                    classes: {
                        root: css(styles.helperText)
                    }
                },
                // FormControlProps: {
                //     classes: {
                //         root: css(styles.formControl)
                //     }
                // }
            }
        };
    }

    get typeProps() {
        return this.type ? _.merge(this.allProps[TYPES.base], this.allProps[this.type]) : this.allProps[TYPES.base];
    }

    setCaretPosition = () => {
        const elem = this.passwordRef;
        if (_.get(elem, 'selectionStart')) {
            elem.setSelectionRange(elem.selectionStart, elem.selectionStart);
        }
        elem.focus();
    };

    render() {
        const omitKeys = [
            'customType',
            'onPressUnits',
            'unitDisabled',
            'unitStyles',
            'minDate',
            'maxDate',
            'hintText',
            'openDatePicker',
            'onDateChange',
            'onUnitChange',
            'unitOptions',
            'onChangeText',
            'isUnitDisabled',
            't',
            'inputClassName',
            'isDisabledTextOverflow',
            'dateFormat',
            'defaultDate',
            'tReady',
            'onClear',
            'isCustomizedSearchBar',
            'onPressEnter',
            'textFieldClassName',
        ];
        const props = _.omit(this.props, omitKeys);
        const allPropsToPass = {
            className: classnames(props.className),
            type: this.typeProps.type,
            ...props,
            InputProps: _.merge(this.typeProps.InputProps, props.InputProps),
            InputLabelProps: _.merge(_.omit(this.typeProps.InputLabelProps, 'FormLabelClasses'), props.InputLabelProps),
            FormHelperTextProps: _.merge(this.typeProps.FormHelperTextProps, props.FormHelperTextProps),
            // FormControlProps={_.merge(this.typeProps.FormControlProps, props.FormControlProps)}
            onChange: this.onChange,
            inputRef: this.type === 'password' ? this.setRef : undefined,
        };
        return (
            <React.Fragment>
                {this.props.hintText ? (
                    <IconButton
                        id="hintTextButton"
                        type="fa"
                        name="question-circle"
                        size={spacing.s3}
                        isSmall={true}
                        onClick={this.openHint}
                        color="secondary"
                        className={css(styles.button)}
                    />
                ) : null}
                {this.type === CUSTOM_TYPES.time ?
                    <TimePicker {...allPropsToPass} /> : (
                        <TextField
                            {...allPropsToPass}
                            onFocus={this.props.onFocus}
                            onBlur={this.props.onBlur}
                            onKeyPress={this.props.onPressEnter ? this.onKeyPress : null}
                        />
                    )}
            </React.Fragment>
        );
    }
}

export default translate()(Input);

const SEARCH_BAR_INPUT_HEIGHT = 40;
const SEARCH_BAR_INPUT_WIDTH = 230;

const styles = StyleSheet.create({
    ...importantStyles({
        root: {
            display: 'flex',
            flexWrap: 'wrap',
        },
        textField: {
            border: '1px solid',
            borderColor: baseColors.grey70,
            borderRadius: spacing.s1,
            marginTop: 0,
            paddingLeft: spacing.s3 - 2,
            color: baseColors.black
        },
        disabledTextField: {
            color: baseColors.grey40,
            backgroundColor: baseColors.grey90,
        },
        textFieldSearchPadding: {
            paddingLeft: 0
        },
        input: {
            marginTop: spacing.s3
        },
        inputSearch: {
            marginTop: 0,
            padding: 0,
            height: SEARCH_BAR_INPUT_HEIGHT,
            width: SEARCH_BAR_INPUT_WIDTH
        },
        customizedInput: {
            color: baseColors.grey40,
            marginTop: spacing.s2,
            marginBottom: spacing.s1,
        },
        adornment: {
            maxHeight: 'none'
        },
        inputFocused: {
            borderColor: baseColors.secondary,
        },
        labelFocused: {
            color: baseColors.grey40,
        },
        labelAnimated: {
            transitionProperty: 'all'
        },
        labelShrink: {
            transform: 'translate(0, 2px) scale(1)',
            ...appFonts.xsRegular,
            paddingTop: spacing.s0
        },
        labelShrinkSmall: {
            ...appFonts.smRegular
        },
        helperText: {
            color: baseColors.dangerDarker
        },
        adornmentIcon: {
            marginRight: spacing.s3,
            display: 'inline-block'
        },
        adornmentIconDisabled: {
            marginRight: 0
        },
        adornmentIconSearch: {
            marginRight: spacing.s2
        },
        adornmentIconButton: {
            marginRight: spacing.s1
        },
        units: {
            paddingTop: 0,
            paddingLeft: spacing.s3,
            paddingRight: spacing.s3,
            borderRadius: spacing.s5,
            textTransform: 'lowercase',
            whiteSpace: 'nowrap',
            borderColor: 'transparent',
            color: baseColors.grey40,
            textAlign: 'center'
        },
        unitsRegularFont: {
            ...appFonts.smRegular
        },
        unitsNotDisabled: {
            borderColor: baseColors.secondary,
            color: baseColors.secondary
        },
        button: {
            position: 'absolute',
            top: -spacing.s1,
            right: -spacing.s1
        },
        noTopMargin: {
            marginTop: -spacing.s2
        }
    }),
    underline: {
        '&:before': {
            content: 'none !important',
        },
        '&:after': {
            content: 'none !important',
        },
    },
    label: {
        ...importantClass({
            paddingLeft: spacing.s3,
            paddingTop: spacing.s1,
            transform: 'translate(0, 10px) scale(1)',
            color: baseColors.grey40,
            maxWidth: '100%',
            zIndex: 1
        })
    },
    customizedLabel: {
        ...importantClass({
            paddingLeft: spacing.s9,
            paddingTop: spacing.s1 + 3,
        }),
    },
    disabledLabel: {
        zIndex: 1,
    }
});
