import PropTypes from 'prop-types';
import React, { Fragment, PureComponent } from 'react';
import { css, StyleSheet } from 'aphrodite-jss';
import _ from 'lodash';
import { appFonts, baseColors, importantClass, layoutStyle, spacing } from '../../../../styles';
import {
    AsyncComponent,
    ActionSheet,
    components as Core,
    ENTITIES,
    getUserName,
    Modal,
    ROUTES,
} from '../../../core';
import EventHeader from '../EventHeader';
import WithEventDetailsBase, { styles as baseStyles } from './EventDetailsBase';
import { components as Settings, constants as settingsConstants } from '../../../settings';
import { components as Feeds } from '../../../feeds';
import { constants as invitationConstants } from '../../../invitations';
import EventBonus from '../EventBonus/EventBonus';

const NUMBER_OF_LINES = 10;

class EventDetails extends PureComponent {
    static propTypes = {
        id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
        event: PropTypes.object.isRequired,
        isLoadingEvent: PropTypes.bool,
        i18n: PropTypes.object.isRequired,
        eventTime: PropTypes.func.isRequired,
        owner: PropTypes.object.isRequired,
        eventType: PropTypes.string.isRequired,
        tabs: PropTypes.array.isRequired,
        selectTab: PropTypes.func.isRequired,
        selectedTab: PropTypes.object.isRequired,
        isAboutTabSelected: PropTypes.bool,
        isStreamTabSelected: PropTypes.bool,
        options: PropTypes.array,
        destructiveButtonIndex: PropTypes.number,
        history: PropTypes.object.isRequired,
        getWarningProps: PropTypes.func.isRequired,
        isDeleting: PropTypes.bool,
        isAdminCreated: PropTypes.bool,
        eventStartEndString: PropTypes.string.isRequired
    };

    static defaultProps = {
        isDeleting: false,
        isLoadingEvent: false,
        isAboutTabSelected: true,
        isStreamTabSelected: false,
        options: [],
        destructiveButtonIndex: 0,
        isAdminCreated: false
    };

    componentDidUpdate(prevProps) {
        const { isDeleting, history } = this.props;
        if (!isDeleting && prevProps.isDeleting) {
            history.replace(ROUTES.eventsAll());
        }
    }

    renderPrivacySection = () => (
        this.props.event.isMember === 1 ? (
            <Settings.PrivacySection
                slug={settingsConstants.PRIVACY_SLUGS.EVENTS}
                isLightStyle={true}
            />
        ) : null
    );

    get renderTabBarHeader() {
        const { eventStartEndString, event: { eventName, eventLocation, eventAddress, eventId }, owner, i18n } = this.props;
        return (
            <div>
                <Core.Title title={eventName} />

                <div className={css(styles.headerEventInfoContainer)}>
                    <Core.Icon name="calendar-alt" size={spacing.s3} className={css(styles.icon)} type="fa" />
                    <div>
                        <p className={css(styles.eventTime)}>{eventStartEndString}</p>
                    </div>
                </div>

                {eventLocation || eventAddress ? (
                    <div className={css(styles.headerEventInfoContainer, styles.eventLocation)}>
                        <Core.Icon name="location-arrow" size={spacing.s3} className={css(styles.locationIcon)} type="fa" />
                        <div>
                            {eventLocation ? <p>{eventLocation}</p> : null}
                            {eventAddress ? <p>{eventAddress}</p> : null}
                        </div>
                    </div>
                )
                    : null }

                <p>{`${i18n.t('created_by')} ${getUserName(owner)}`}</p>
                <p>{this.props.eventType}</p>

                <div className={css(styles.headerEventInfoContainer, styles.eventBonus)}>
                    <EventBonus eventId={eventId} showInfoButton={true} />
                </div>

            </div>
        );
    }

    openOptions = event => {
        ActionSheet.open(this.options, this.props.destructiveButtonIndex, undefined, event);
    };

    get rightNavIconProps() {
        return this.options.length ? {
            name: 'ellipsis-h',
            onClick: this.openOptions,
            id: 'moreBtn'
        } : null;
    }


    get showSelectedTab() {
        if (this.props.isAboutTabSelected) {
            return this.renderAboutTab;
        } else if (this.props.isStreamTabSelected) {
            return this.renderStreamTab;
        }
        return null;
    }

    get renderAboutTab() {
        const { i18n, event, id } = this.props;
        return (
            <div className={css(styles.cardHeader)}>
                <EventHeader itemId={id} />
                {this.renderPrivacySection()}
                <div className={css(styles.title)}>
                    {i18n.t('details')}
                </div>
                <Core.TextSeeMore
                    className={css(appFonts.mdRegular, styles.subTitleWrapper)}
                    numberOfLines={NUMBER_OF_LINES}
                    isCollapseHidden={false}
                    isExpandHidden={false}>
                    {_.get(event, 'eventDescription')
                        ? <Core.InnerHtml htmlText={_.get(event, 'eventDescription')} />
                        : i18n.t('noDescription')
                    }
                </Core.TextSeeMore>
            </div>
        );
    }

    get renderStreamTab() {
        const { event, id } = this.props;
        return (
            <>
                {this.renderStreamHeader}
                <Feeds.StreamFilter
                    filtersByTimeAdded={true}
                    hasShareNewsComponent={false}
                    containerClassName={css(styles.container)}>
                    <Feeds.FeedList
                        extraData={event}
                        entityType={ENTITIES.event}
                        entityId={id}
                        canLoadContent={!!_.get(event, 'isMember')}
                        footerRefresh={true}
                        onRef={node => {
                            this.node = node;
                        }}
                    />
                </Feeds.StreamFilter>
            </>
        );
    }

    get renderStreamHeader() {
        return (
            <div className={css(styles.stream)}>
                <Feeds.ShareNews
                    postParams={{
                        postToEntityId: this.props.event.eventId,
                        postToEntityType: ENTITIES.event
                    }}
                    title={this.props.event.eventName}
                />
            </div>
        );
    }

    get options() {
        return _.map(this.props.options, item => ({ ...item, onPress: this[item.id] || this.props[item.id] }));
    }

    onInviteEvent = () => {
        const { event: { eventId }, history } = this.props;
        history.push(ROUTES.inviteToEvent(eventId, invitationConstants.ENTITIES.EVENT));
    };

    onEditEvent = () => {
        const { event: { eventId }, isAdminCreated } = this.props;
        Modal.open(AsyncComponent(() => import('../CreateEvent')), { isModal: true, id: eventId, isAdminCreated }, {
            cancelable: false,
            isNoPadding: true,
            isContainer: true,
            isFullHeight: true,
        });
    };

    showDeletionWarning = onConfirm => {
        const { i18n, getWarningProps } = this.props;

        const onDelete = () => {
            onConfirm();
            this.closeWarning();
        };

        this.closeWarning = Modal.open(
            Core.InfoModal,
            {
                ...getWarningProps(),
                buttons: [
                    { text: i18n.t('delete_event'), onPress: () => onDelete(), isDangerText: true, isDisabled: true },
                    { text: i18n.t('button_cancel'), onPress: () => this.closeWarning() },
                ],
            },
            { isContainer: true, isNoPadding: true, isMaxWidthLimited: true, fadeTransition: true }
        );
    };

    render() {
        const { id, event, isLoadingEvent, tabs, selectTab, selectedTab, isDeleting } = this.props;
        return (
            <Core.Layout.WiderContainer className={css(layoutStyle.fh)}>
                <div className={css(styles.innerContainer)}>
                    <Core.BlockingLoading isLoading={isLoadingEvent || isDeleting} />
                    {id && event ? (
                        <Core.EntityDetailsLayout
                            tabsProps={{
                                tabs,
                                onChange: selectTab,
                                activeTab: selectedTab,
                                headerComponent: this.renderTabBarHeader
                            }}
                            title={_.get(this.props.event, 'eventName')}
                            rightNavIconProps={this.rightNavIconProps}>
                            <Fragment>
                                {_.get(this.props, 'event.isMember') ? this.showSelectedTab : this.renderAboutTab}
                            </Fragment>
                        </Core.EntityDetailsLayout>
                    )
                        : <Core.NotAvailableContent text={this.props.i18n.t('contentIsDeletedOrInappropriate')} />}
                </div>
            </Core.Layout.WiderContainer>
        );
    }
}

const styles = StyleSheet.create({
    ...baseStyles,
    headerEventInfoContainer: {
        display: 'flex',
        ...appFonts.mdMedium,
        margin: `${spacing.s0}px 0 ${spacing.s2}px`
    },
    icon: {
        marginRight: spacing.s1,
        alignSelf: 'center',
        color: baseColors.grey20,
    },
    eventLocation: {
        color: baseColors.secondary,
    },
    locationIcon: {
        marginRight: spacing.s1,
        color: baseColors.grey20,
        marginTop: spacing.s0,
    },
    title: {
        ...appFonts.lgMedium,
        margin: `${spacing.s3}px 0`
    },
    container: importantClass({
        paddingBottom: spacing.s2
    }),
    eventTime: {
        ...appFonts.mdMedium,
        color: baseColors.grey20,
    },
    eventBonus: {
        paddingTop: spacing.s2,
        paddingBottom: spacing.s3
    }
});

export default WithEventDetailsBase(EventDetails);
