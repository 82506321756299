import React, { PureComponent } from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import _ from 'lodash';
import classnames from 'classnames';
import { StyleSheet, css } from 'aphrodite-jss';
import { spacing, baseColors, containerStyle } from '../../../../styles';
import { components as Core, ROUTES, Modal } from '../../../core';
import { getDeletionString } from '../../services/helper';
import GoalIcon from '../GoalIcon';
import WeeklyGoalTag from '../WeeklyGoalTag';
import BonusChallengeMark from '../BonusChallengeMark';
import WithSetGoalCardSectionBase, { styles as baseStyles } from './SetGoalCardSectionBase';

class SetGoalCardSection extends PureComponent {
    static propTypes = {
        goals: PropTypes.array,
        iconSize: PropTypes.number,
        deleteGoal: PropTypes.func.isRequired,
        keyExtractor: PropTypes.func.isRequired
    };

    static defaultProps = {
        goals: [],
        iconSize: spacing.s11
    };

    onClick = id => {
        this.props.history.push(ROUTES.challengeDetails(id));
    }

    showDeletionWarning = (e, goal) => {
        e.stopPropagation();
        const { i18n, deleteGoal } = this.props;
        const onDelete = () => {
            deleteGoal(goal.challengeId);
            this.closeWarningModal();
        };

        this.closeWarningModal = Modal.open(
            Core.InfoModal,
            {
                isButtonVisible: false,
                text: getDeletionString(goal),
                title: i18n.t('areYouSure'),
                checkboxLabel: i18n.t('deleteCommunityCheckbox'),
                textStyle: css(styles.warningModalText),
                buttons: [
                    { text: i18n.t('button_delete_challenge', { challengeType: goal.challengeType }), onPress: () => onDelete(), isDangerText: true, isDisabled: true },
                    { text: i18n.t('button_cancel'), onPress: () => this.closeWarningModal() },
                ],
            },
            { isContainer: true, isNoPadding: true, isMaxWidthLimited: true, fadeTransition: true }
        );
    };

    renderSetGoalCard = (goal, index) => {

        const cardId = _.camelCase(`${goal.challengeName}GoalCard`);
        const deleteBtnId = _.camelCase(`${goal.challengeName}DeleteButton`);

        return (
            <div
                id={cardId}
                className={css(styles.goalCard, containerStyle.listItemfocusVisible)}
                key={this.props.keyExtractor(goal, index)}
                onClick={() => this.onClick(goal.challengeId)}
                tabIndex="0"
                role="button"
                aria-label={goal.challengeName}>
                <div className={css(styles.mainInfo)}>
                    <GoalIcon
                        goal={goal}
                        isDisabledOnPress={true}
                        size={this.props.iconSize}
                        className={css(styles.icon)}
                        challengeId={goal.challengeId}
                    />
                    <div className={css(styles.infoSection)}>
                        <p className={css(styles.goalTitle)}>{goal.challengeName}</p>
                        <WeeklyGoalTag challengeId={goal.challengeId} />
                        <BonusChallengeMark challengeId={goal.challengeId} containerClassName={css(styles.bonusMark)} />
                    </div>
                </div>
                <div id={deleteBtnId} className={css(styles.deleteButton)} onClick={e => this.showDeletionWarning(e, goal)}>
                    <Core.Icon
                        type="fa"
                        name="trash-alt"
                        size={spacing.s3}
                        color={baseColors.grey40}
                    />
                </div>
            </div>
        );
    }

    render() {
        return (
            <div className={classnames(css(styles.iconContainer), this.props.className)}>
                {_.map(this.props.goals, this.renderSetGoalCard)}
            </div>
        );
    }
}

export default withRouter(WithSetGoalCardSectionBase(SetGoalCardSection));

const styles = StyleSheet.create({
    ...baseStyles,
    icon: {
        transition: 'all 2s'
    },
    goalCard: {
        ...baseStyles.goalCard,
        width: '33%',
        boxShadow: '0px 2px 8px rgba(0, 0, 0, 0.15)',
        borderRadius: spacing.s1,
        marginRight: spacing.s1,
        cursor: 'pointer',
    },
    deleteButton: {
        ...baseStyles.deleteButton,
        display: 'flex',
        borderRadius: 4,
        '&:hover': {
            backgroundColor: baseColors.grey85,
        }
    }
});
