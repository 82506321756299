import React, { PureComponent, Fragment } from 'react';
import PropTypes from 'prop-types';
import { isIE } from 'react-device-detect';
import { css, StyleSheet } from 'aphrodite-jss';
import { withLastLocation } from 'react-router-last-location';
import _ from 'lodash';
import { ActionSheet, components as Core, ROUTES, Modal, AsyncComponent, Storage, animations } from '../../../core';
import ChallengeDetailsHeader from './ChallengeDetailsHeader';
import ChallengeDetailsSubHeader from './ChallengeDetailsSubHeader';

import ChallengeParticipants from './ChallengeParticipants';
import ChallengeInfo from './ChallengeInfo';
import TeamDetails from '../TeamDetails';
import Leaderboard from './Leaderboard';
import Progress from './Progress';
import WeeklyGoalTag from '../WeeklyGoalTag';
import { components as Feeds, constants as feedsConstants } from '../../../feeds';
import WithChallengeDetailsBase, { styles as baseStyles } from './ChallengeDetailsBase';
import { isMember, isPersonal, getChallengeOptions, isTeamChallenge, getDeletionString } from '../../services/helper';
import { components as Settings, constants as settingsConstants } from '../../../settings';
import { CHALLENGE_ENTITY_TYPES } from '../../constants';
import { constants as invitationConstants } from '../../../invitations';
import { spacing, appFonts, importantClass, baseColors, headerHeight } from '../../../../styles';

const SAVED_LAST_LOCATION = 'savedLastLocation';
const LOCATIONS = [ROUTES.home(), ROUTES.explore(), ROUTES.challenges()];
const YOU_UPON_THE_SCREEN = 'upon';
const YOU_BELOW_THE_SCREEN = 'below';

class ChallengeDetails extends PureComponent {
    static propTypes = {
        i18n: PropTypes.object.isRequired,
        actions: PropTypes.object.isRequired,
        history: PropTypes.object.isRequired,
        DEFINED_TABS: PropTypes.object.isRequired,
        tabs: PropTypes.array.isRequired,
        challenge: PropTypes.object.isRequired,
        challengeId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
        isLoading: PropTypes.bool.isRequired,
        selectedTab: PropTypes.object.isRequired,
        onTabChange: PropTypes.func.isRequired,
        contentDeletedText: PropTypes.string.isRequired,
        challengeName: PropTypes.string.isRequired,
        role: PropTypes.string.isRequired,
        isExtendedRole: PropTypes.bool.isRequired,
        isShowingOnboarding: PropTypes.bool,
        youButtonLabel: PropTypes.string.isRequired,
        isLeaderboardTab: PropTypes.bool.isRequired,
        zeroParticipants: PropTypes.string.isRequired,
        isYouOnLeaderboard: PropTypes.bool.isRequired,
        deleteChallenge: PropTypes.func.isRequired,
        isMyTeam: PropTypes.bool.isRequired,
        isSmartModeGoal: PropTypes.bool.isRequired,
        smartModeStatusText: PropTypes.string.isRequired,
        getSmartModeInfoModalProps: PropTypes.func.isRequired,
    };

    static defaultProps = {
        isShowingOnboarding: false
    };

    constructor(props) {
        super(props);
        this.tabsMap = {
            [this.props.DEFINED_TABS.about.id]: this.renderAboutTab,
            [this.props.DEFINED_TABS.progress.id]: this.renderProgressTab,
            [this.props.DEFINED_TABS.stream.id]: this.renderStreamTab,
            [this.props.DEFINED_TABS.leaderboard.id]: this.renderLeaderboardTab,
            [this.props.DEFINED_TABS.myTeam.id]: this.renderTeamTab,
        };
        this.state = { savedLastLocation: null, isItemVisibleOnScreen: false, youButtonPosition: null };
    }

    async componentDidMount() {
        if (this.shouldSaveLocation) {
            Storage.setItem(SAVED_LAST_LOCATION, _.get(this.props, 'lastLocation.pathname'));
        } else {
            const savedLastLocation = await Storage.getItem(SAVED_LAST_LOCATION);
            if (savedLastLocation) {
                this.setState({ savedLastLocation });
            }
        }
    }

    get shouldSaveLocation() {
        const { challenge } = this.props;
        if (challenge) {
            return this.lastLocationPathName === ROUTES.setPersonalGoal() || this.lastLocationPathName === ROUTES.me()
                ? isPersonal(challenge)
                : isTeamChallenge(challenge) && !isMember(challenge);
        }
        return null;
    }

    get shouldUseLastLocation() {
        return _.includes(LOCATIONS, this.lastLocationPathName);
    }

    get lastLocationPathName() {
        return _.get(this.props, 'lastLocation.pathname');
    }

    get leaveChallengeOption() {
        return {
            title: this.props.i18n.t('leaveChallenge.menu', { challengeName: _.get(this.props.challenge, 'challengeName', '') }),
            onPress: this.leaveChallenge
        };
    }

    get deleteChallengeOption() {
        return {
            title: this.deleteChallengeTitle,
            onPress: () => this.showDeletionWarning()
        };
    }

    get emptyComponent() {
        return (
            <Core.EmptyListSimple message={this.props.i18n.t('noPosts')} iconName="comment" />
        );
    }

    // is used from base
    goBack = () => this.props.history.goBack();

    // is used from parent
    close = () => {
        const { savedLastLocation } = this.state;
        if (this.shouldUseLastLocation) {
            return this.props.history.push(this.lastLocationPathName);
        }
        savedLastLocation
            ? this.props.history.push(savedLastLocation)
            : this.props.history.push(_.get(this.props, 'location.state.deleteRedirect') ||
                ROUTES.home());
    };

    showMoreSmartModeInfo = event => {
        event.stopPropagation();
        const infoModalProps = this.props.getSmartModeInfoModalProps();
        this.closeInfoModal = Modal.open(
            Core.InfoModal,
            {
                ...infoModalProps,
                buttons: [{ text: infoModalProps.buttonTitle, onPress: () => this.closeInfoModal() }],
                textStyle: styles.smartModeAdditionalInfo,
                iconClassName: css(styles.iconClassName),
            },
            {
                isContainer: true,
                isTransparent: true,
                isNoPadding: true,
                fadeTransition: true,
                isMaxWidthLimited: true,
            }
        );
    }

    renderAboutTab = () => (
        <Fragment>
            {this.props.isSmartModeGoal !== undefined ? (
                <div className={css(styles.smartModePadding)}>
                    {this.smartModeSection}
                </div>
            ) : null}
            <ChallengeParticipants challengeId={this.props.challengeId} />
            {isMember(this.props.challenge) && !isPersonal(this.props.challenge)
                ? (
                    <div className={css(styles.privacySection)}>
                        <Settings.PrivacySection slug={settingsConstants.PRIVACY_SLUGS.CHALLENGES} isLightStyle={true} hasVerticalPadding={true} />
                    </div>
                )
                : null
            }
            <ChallengeInfo challengeId={this.props.challengeId} />
        </Fragment>
    );

    renderProgressTab = () => <Progress challengeId={this.props.challengeId} />;

    renderStreamTab = () => (
        <Fragment>
            <div className={css(styles.streamHeader)}>
                <Feeds.ShareNews
                    postParams={{
                        postToEntityId: this.props.challenge.challengeId,
                        postToEntityType: feedsConstants.STREAM_ENTITY_TYPES.challenge
                    }}
                    title={this.props.challenge.challengeName}
                />
            </div>
            <Feeds.StreamFilter
                filtersByTimeAdded={true}
                hasShareNewsComponent={false}
                containerClassName={css(styles.container)}>
                <Feeds.FeedList
                    entityId={this.props.challengeId}
                    entityType={feedsConstants.STREAM_ENTITY_TYPES.challenge}
                    canLoadContent={true}
                    ListEmptyComponent={this.emptyComponent}
                />
            </Feeds.StreamFilter>

        </Fragment>
    );

    renderLeaderboardTab = () => (
        <Fragment>
            {isMember(this.props.challenge) ? (
                <Settings.PrivacySection
                    slug={settingsConstants.PRIVACY_SLUGS.CHALLENGE_LEADERBOARD}
                    hasVerticalPadding={true}
                    isLightStyle={true}
                />
            )
                : null}
            <Leaderboard
                challengeId={this.props.challengeId}
                itemLeaderboardRef={this.itemLeaderboardRef}
                isItemVisibleOnScroll={this.isItemVisibleOnScroll}
            />
        </Fragment>
    );

    renderTeamTab = () => (
        <TeamDetails
            userEntityId={this.props.challenge.userEntity.id}
            entityId={this.props.challenge.userEntity.id}
            challengeId={this.props.challengeId}
            isPage={false}
            isTab={true}
        />
    );

    get deleteChallengeTitle() {
        return this.props.i18n.t('deleteChallenge.menu', { challengeName: _.get(this.props.challenge, 'challengeName') });
    }

    openOptions = event => ActionSheet.open(this.getOptions(this.inviteToChallenge, this.updateChallengeImage), this.getDestructiveButtonIndex(), undefined, event);

    inviteToChallenge = () => {
        this.props.history.push(ROUTES.inviteToChallenge(this.props.challengeId, invitationConstants.ENTITIES.CHALLENGE));
    };
    getDestructiveButtonIndex = () => _.findIndex(this.getOptions(), { title: this.deleteChallengeTitle });

    leaveChallenge = () => {
        const { challenge, i18n, actions } = this.props;
        let leaveMessage = i18n.t('leave_goal_confirmation');
        //check if team challenge
        if (_.get(challenge, 'userEntity.entityType') !== CHALLENGE_ENTITY_TYPES.user) {
            if (_.get(challenge, 'userEntity.numberOfUsers', 0) === '1') {
                leaveMessage = `${i18n.t('leaveTeam')} ${_.get(challenge, 'userEntity.name')} ${i18n.t('leaveTeamLastMember')}`;
            } else {
                leaveMessage = `${i18n.t('leaveTeam')} ${_.get(challenge, 'userEntity.name')} ${i18n.t('leaveTeamChallenge')}`;
            }
        }

        const onLeaveChallenge = () => {
            actions.leaveChallenge(challenge.challengeId);
            this.closeInfoModal();
        };

        this.closeInfoModal = Modal.open(
            Core.InfoModal,
            {
                title: i18n.t('areYouSure'),
                text: leaveMessage,
                isButtonVisible: false,
                buttons: [
                    { text: i18n.t('button_leave_challenge'), onPress: () => onLeaveChallenge(), isDangerText: true },
                    { text: i18n.t('button_cancel'), onPress: () => this.closeInfoModal() },
                ],
            },
            { isContainer: true, isNoPadding: true, isMaxWidthLimited: true, fadeTransition: true }
        );
    };

    showDeletionWarning = () => {
        const { challenge, i18n, deleteChallenge, challengeType } = this.props;
        const onDelete = () => {
            deleteChallenge();
            this.closeWarningModal();
        };

        this.closeWarningModal = Modal.open(
            Core.InfoModal,
            {
                isButtonVisible: false,
                text: getDeletionString(challenge),
                title: i18n.t('areYouSure'),
                checkboxLabel: i18n.t('deleteCommunityCheckbox'),
                textStyle: css(styles.warningModalText),
                buttons: [
                    { text: i18n.t('button_delete_challenge', { challengeType }), onPress: () => onDelete(), isDangerText: true, isDisabled: true },
                    { text: i18n.t('button_cancel'), onPress: () => this.closeWarningModal() },
                ],
            },
            { isContainer: true, isNoPadding: true, isMaxWidthLimited: true, fadeTransition: true }
        );
    };

    updateChallengeImage = () => {
        Modal.open(AsyncComponent(() => import('../EditChallengeImage')), {
            challengeId: this.props.challengeId
        }, { isContainer: true });
    };

    getOptions = (inviteCallback, updateCallback) => {
        const { isExtendedRole, role, challenge } = this.props;
        return getChallengeOptions(inviteCallback, updateCallback, isExtendedRole, role, challenge, this.leaveChallengeOption, this.deleteChallengeOption);
    };

    get rightNavIconProps() {
        const { challenge, challenge: { isCreator }, isExtendedRole } = this.props;
        return (isMember(challenge) || isExtendedRole || isCreator) && this.getOptions(this.inviteToChallenge).length ? {
            name: 'ellipsis-h',
            onClick: this.openOptions
        } : null;
    }

    get challengeDetailsNavbarProps() {
        const headerContainerClassName = isIE ? styles.navbarContainerIE : styles.navbarContainer;
        return {
            navbarButtonsClassName: styles.navbarButtons,
            isChallengeDetailsPage: true,
            headerContainerClassName,
        };
    }

    get renderTabBarHeader() {
        const { challenge, challengeId, challengeName, zeroParticipants } = this.props;
        const showParticipants = (
            <Fragment>
                {zeroParticipants ? (
                    <p className={css(styles.zeroParticipantsText)}>{zeroParticipants}</p>
                ) : null}
                <ChallengeParticipants challengeId={challengeId} />
            </Fragment>
        );

        return (
            <div>
                <ChallengeDetailsHeader challengeId={challengeId} />
                <Core.SmallerContainer className={css(styles.tabBarHeader)}>
                    <WeeklyGoalTag challengeId={challengeId} containerClassName={css(styles.weeklyGoalWrapper)} />
                    <Core.Title title={challengeName} titleClassName={css(styles.title)} />
                    <Fragment>
                        <ChallengeDetailsSubHeader challengeId={challengeId} />
                        {isMember(challenge) ? null : showParticipants}
                    </Fragment>
                </Core.SmallerContainer>
            </div>
        );
    }

    get youButtonContent() {
        const { youButtonLabel } = this.props;
        const arrowDirection = this.state.youButtonPosition === YOU_UPON_THE_SCREEN ? 'arrow-up' : 'arrow-down';
        return (
            <div className={css(styles.youButtonContent)}>
                <p className={css(styles.youButtonText)}>{youButtonLabel}</p>
                <Core.Icon
                    type="fa"
                    fill="regular"
                    name={arrowDirection}
                    size={spacing.s3}
                    color={baseColors.black}
                />
            </div>
        );
    }

    get showYouButton() {
        const { isLeaderboardTab, isYouOnLeaderboard, isMyTeam } = this.props;
        // eslint-disable-next-line
        return !this.state.isItemVisibleOnScreen && isLeaderboardTab && (isYouOnLeaderboard || isMyTeam);
    }

    createSmartModeMarkup = () => ({ __html: this.props.smartModeStatusText });

    get smartModeSection() {
        return (
            <div className={css(styles.smartModeSection)}>
                <Core.IconButton
                    onClick={this.showMoreSmartModeInfo} name="question-circle" type="fa" fill="solid" color={baseColors.black}
                    size={spacing.s3}
                />
                {/* eslint-disable-next-line react/no-danger */}
                <div dangerouslySetInnerHTML={this.createSmartModeMarkup()} className={css(styles.smartModeText)} />
            </div>
        );
    }

    itemLeaderboardRef = ref => this.userItemNode = ref;

    onLeaderboardScrollToYou = () => {
        this.userItemNode && animations.scrollToNode(this.userItemNode);
    };

    isItemVisibleOnScroll = isVisible => {
        this.setState({ isItemVisibleOnScreen: isVisible });
        const rect = this.userItemNode && this.userItemNode.getBoundingClientRect();
        const offset = 80;
        const elemTop = rect.top + offset;
        const elemBottom = rect.bottom - offset;

        const viewportHeight = window.innerHeight || document.documentElement.clientHeight;

        if (elemTop > viewportHeight) this.setState({ youButtonPosition: YOU_BELOW_THE_SCREEN });
        else if (elemBottom < 0) this.setState({ youButtonPosition: YOU_UPON_THE_SCREEN });

    }

    render() {
        const { contentDeletedText, isLoading, challenge, challengeId, tabs, onTabChange,
            selectedTab, challengeName, isShowingOnboarding } = this.props;
        const youButtonPosition = this.state.youButtonPosition === YOU_BELOW_THE_SCREEN ? styles.bottomYouButton : styles.topYouButton;
        return (
            <>
                <Core.BlockingLoading isLoading={isLoading} />
                <div className={css(styles.cardBody)}>
                    {challenge ? (
                        <Core.EntityDetailsLayout
                            tabsProps={{
                                tabs,
                                isHorizontal: true,
                                isTabsContentVisible: isMember(challenge),
                                onChange: onTabChange,
                                activeTab: selectedTab,
                                isChallengeDetailsPage: true,
                                headerComponent: this.renderTabBarHeader,
                                hasSectionBorder: isMember(challenge) ? true : false
                            }}
                            title={challengeName}
                            isOnboarding={isShowingOnboarding}
                            rightNavIconProps={this.rightNavIconProps}
                            challengeDetailsNavbarProps={this.challengeDetailsNavbarProps}>
                            <div>
                                {isMember(challenge) ? (
                                    <Fragment>
                                        {this.tabsMap[selectedTab.id]
                                            ? this.tabsMap[selectedTab.id]() : null}
                                    </Fragment>
                                ) : (
                                    <Fragment>
                                        <ChallengeInfo challengeId={challengeId} />
                                    </Fragment>
                                )}
                            </div>
                        </Core.EntityDetailsLayout>
                    ) : (
                        <Fragment>
                            {!isLoading && <Core.NotAvailableContent text={contentDeletedText} />}
                        </Fragment>
                    )}
                    {this.showYouButton ? (
                        <Core.Button
                            fullWidth={true}
                            isSubmit={true}
                            onPress={this.onLeaderboardScrollToYou}
                            className={css(styles.youButtonContainer, youButtonPosition)}>
                            {this.youButtonContent}
                        </Core.Button>
                    ) : null}
                </div>
            </>
        );
    }
}

export default withLastLocation(WithChallengeDetailsBase(ChallengeDetails));

const NAVBAR_HEIGHT = 48;
const NAVBAR_MAX_WIDTH = 980;
const YOU_BUTTON_MIN_WIDTH = 100;
const YOU_BUTTON_HEIGHT = 44;

const navbarCommonStyles = {
    height: NAVBAR_HEIGHT,
    paddingTop: spacing.s5,
    backgroundColor: 'rgba(0,0,0,0.0)',
};

const styles = StyleSheet.create({
    ...baseStyles,
    title: {
        ...appFonts.xxxlBold,
        marginBottom: 0,
    },
    navbarContainer: {
        ...navbarCommonStyles,
    },
    navbarContainerIE: {
        ...navbarCommonStyles,
        position: 'fixed',
        width: '100%',
        maxWidth: NAVBAR_MAX_WIDTH,
        '@media (max-width:996px)': {
            padding: `0 ${spacing.s1}px`
        },
    },
    navbarButtons: importantClass({
        backgroundColor: 'rgba(0,0,0,0.5)'
    }),
    tabBarHeader: {
        marginTop: -spacing.s4,
    },
    weeklyGoalWrapper: {
        marginBottom: spacing.s0,
    },
    privacySection: {
        marginTop: spacing.s5
    },
    youButtonContainer: importantClass({
        backgroundColor: baseColors.white,
        width: 'auto',
        minWidth: YOU_BUTTON_MIN_WIDTH,
        height: YOU_BUTTON_HEIGHT,
        position: 'fixed',
        zIndex: 1,
        boxShadow: '0px 2px 10px rgba(0, 0, 0, 0.1), 0px 4px 54px rgba(0, 0, 0, 0.08), 0px 5px 20px rgba(0, 0, 0, 0.15)',
        display: 'flex',
        alignSelf: 'center',
        cursor: 'pointer'
    }),
    bottomYouButton: importantClass({
        bottom: spacing.s7
    }),
    topYouButton: importantClass({
        top: headerHeight + spacing.s19
    }),
    youButtonContent: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        color: baseColors.black
    },
    youButtonText: {
        marginRight: spacing.s1
    },
    warningModalText: {
        marginBottom: spacing.s3
    },
    smartModeSection: {
        paddingTop: spacing.s3,
        paddingBottom: spacing.s3,
        paddingLeft: spacing.s3,
        paddingRight: spacing.s3,
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        backgroundColor: baseColors.contextLightest,
        borderRadius: spacing.s1
    },
    smartModePadding: {
        paddingTop: spacing.s4
    },
    smartModeText: {
        ...appFonts.mdRegular,
        paddingLeft: spacing.s2
    },
    iconClassName: {
        backgroundColor: baseColors.primary
    },
    smartModeAdditionalInfo: {
        marginTop: spacing.s3,
        color: baseColors.grey20,
        marginBottom: spacing.s7,
    },
    container: importantClass({
        paddingBottom: spacing.s2
    })
});
