import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import _ from 'lodash';
import { css, StyleSheet } from 'aphrodite-jss';
import { withRouter } from 'react-router-dom';
import { layoutStyle, libraryContentImageWidth, libraryContentImageHeight } from '../../../../styles';
import { components as Core, ROUTES } from '../../../core';
import defaultImage from '../../../../image/placeholder_group_o.jpg';
import EventBonus from '../EventBonus/EventBonus';

import WithEventListItemBase, { styles as baseStyles } from './EventListItemBase';

class EventListItem extends PureComponent {
    static propTypes = {
        event: PropTypes.object.isRequired,
        history: PropTypes.object.isRequired,
        eventImage: PropTypes.string.isRequired,
        fullEventDate: PropTypes.string.isRequired,
    };

    onPressSingle = () => {
        this.props.history.push(ROUTES.eventDetails(this.props.event.eventId));
    };

    allMembersPressed = e => {
        e.stopPropagation();
        const { event: { eventId } } = this.props;
        this.props.history.push(ROUTES.eventMembers(eventId));
    };

    render() {
        const {
            eventImage, event, fullEventDate,
            event: { eventName, eventId, eventAttendees }
        } = this.props;
        return (
            <Core.TouchableItem
                id={_.camelCase(`${eventName}Container`)}
                onPressSingle={this.onPressSingle}
                separator={false}
                containerClassName={css(layoutStyle.entityItemMainContainer, layoutStyle.flex, styles.mainContainer)}
                innerContainerClassName={css(layoutStyle.flex, layoutStyle.flex1)}
                image={eventImage || defaultImage}
                imageWidth={libraryContentImageWidth}
                imageHeight={libraryContentImageHeight}
                item={event}>
                <div className={css(styles.innerEventContainer, layoutStyle.flex)}>
                    <div className={css(styles.eventDetailsContainer, layoutStyle.flex)}>
                        <div className={css(layoutStyle.flex1)}>
                            <Core.ExploreItemMeta iconName="calendar-alt" text={fullEventDate} />
                            <Core.ExploreItemTitle text={eventName} />
                            <Core.ExploreItemMembers members={eventAttendees} isEvent={true} />
                            <div className={css(styles.bonusSection)}>
                                <EventBonus eventId={eventId} />
                            </div>
                        </div>
                    </div>
                </div>
            </Core.TouchableItem>
        );
    }
}

export default withRouter(WithEventListItemBase(EventListItem));

export const styles = StyleSheet.create({
    ...baseStyles,
    innerEventContainer: {
        ...baseStyles.innerEventContainer,
    },
    eventDetailsContainer: {
        ...baseStyles.eventDetailsContainer,
        paddingLeft: 0
    },
    mainContainer: {
        ...baseStyles.mainContainer,
        borderBottomStyle: 'solid',
    },
});