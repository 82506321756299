import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { StyleSheet, css } from 'aphrodite-jss';

import { withRouter } from 'react-router-dom';
import { appFonts, baseColors, importantStyles, layoutStyle, spacing } from '../../../../styles';
import WithGoalsInfoBase, { styles as baseStyles } from './GoalsInfoBase';
import SetGoalCardSection from '../SetGoalCardSection';
import { components as Core, ROUTES } from '../../../core';
import Icon from '../../../core/components/Icon';

class GoalsInfo extends PureComponent {
    static propTypes = {
        goals: PropTypes.array,
        isTaskCard: PropTypes.bool,
        isTitleVisible: PropTypes.bool,
        i18n: PropTypes.object.isRequired,
        containerClassName: PropTypes.string,
        detailsDeleteRedirect: PropTypes.string,
        descriptionOne: PropTypes.string.isRequired,
        history: PropTypes.object.isRequired,
    };

    static defaultProps = {
        goals: [],
        isTaskCard: false,
        isTitleVisible: true,
        containerClassName: null,
        detailsDeleteRedirect: undefined
    };

    openPreviousGoals = () => {
        this.props.history.push(ROUTES.previousGoals());
    }


    render() {
        const { containerClassName, goals, detailsDeleteRedirect, isTaskCard, isTitleVisible, descriptionOne, i18n } = this.props;
        return (
            <div className={containerClassName}>
                {isTitleVisible ?
                    <div className={css(styles.recommendedTitle)}>{i18n.t('wellbeing.recommendedGoals')}</div> : null
                }
                <div className={css(styles.recommendedDescription)}>
                    {descriptionOne}
                </div>
                {goals.length ? (
                    <SetGoalCardSection
                        goals={goals}
                        iconSize={spacing.s7}
                        isTaskCard={isTaskCard}
                        detailsDeleteRedirect={detailsDeleteRedirect}
                        className={css(styles.recommendedIconsContainer)}
                        iconClassName={css(layoutStyle.cp)}
                    />
                ) : null}
                <Core.Button
                    id="my-previous-golas-btn"
                    type="text"
                    className={css(styles.buttonPreviousGoals)}
                    onPress={this.openPreviousGoals}>
                    {i18n.t('previousGoalsTitle')}
                    <Icon
                        name="angle-right"
                        type="fa"
                        size={spacing.s6}
                        color={baseColors.secondary}
                        className={css(styles.icon)}
                    />
                </Core.Button>
            </div>
        );
    }
}

export default withRouter(WithGoalsInfoBase(GoalsInfo));

const styles = StyleSheet.create(importantStyles({
    ...baseStyles,
    mainContainer: {
        textAlign: 'center'
    },
    descriptionTwo: {
        marginTop: spacing.s0,
    },
    buttonPreviousGoals: {
        marginTop: spacing.s5,
        ...appFonts.mdRegular,
        paddingLeft: 0,
        color: baseColors.secondary
    },
    icon: {
        paddingLeft: spacing.s1
    }
}));
