import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { css, StyleSheet } from 'aphrodite-jss';
import { withRouter } from 'react-router-dom';
import { baseColors, importantClass, importantStyles, layoutStyle, spacing } from '../../../../styles';
import { Modal, components as Core, AsyncComponent } from '../../../core';
import WithActivityTrackingModalBase, { styles as baseStyles, UNIT_FIELD_LENGTH } from './ActivityTrackingModalBase';
import modalProps from '../CongratsFirstTrackChallenge/modalProps';
import { overTrackedDateFormatted } from '../../services/activitiesHelper';

class ActivityTrackingModal extends PureComponent {
    static propTypes = {
        activity: PropTypes.object.isRequired,
        i18n: PropTypes.object.isRequired,
        close: PropTypes.func.isRequired,
        trackButtonLabel: PropTypes.string,
        title: PropTypes.string.isRequired,
        isLoading: PropTypes.bool,
        fromSampleTask: PropTypes.bool,
        onTrack: PropTypes.func.isRequired,
        deleteActivity: PropTypes.func.isRequired,
        onChange: PropTypes.func.isRequired,
        isFieldVisible: PropTypes.bool.isRequired,
        isRemoveVisible: PropTypes.bool.isRequired,
        getTotalPointsEarned: PropTypes.func.isRequired,
        quantity: PropTypes.string.isRequired,
        subtitle: PropTypes.string.isRequired,
        hasSubtitle: PropTypes.bool,
        error: PropTypes.string,
        isEditingActivityLog: PropTypes.bool
    };

    static defaultProps = {
        isLoading: false,
        trackButtonLabel: undefined,
        fromSampleTask: false,
        hasSubtitle: false,
        error: undefined,
        isEditingActivityLog: false
    };

    // is used from base
    showFirstTrackChallengeScreen = () => {
        this.close = Modal.open(
            AsyncComponent(() => import('../CongratsFirstTrackChallenge')),
            { pointsEarned: this.props.getTotalPointsEarned() },
            { ...modalProps }
        );
    };

    dismissModal = () => this.props.close();

    onFormSubmit = event => {
        event.preventDefault();
        this.props.onTrack();
    };

    get warning() {
        const { warningText } = this.props;
        return (
            <div className={css(styles.warningWrapper)}>
                <Core.Icon
                    type="fa"
                    fill="solid"
                    size={spacing.s3}
                    color={baseColors.warn}
                    name="exclamation-triangle"
                />
                <p className={css(styles.warningText)}>{warningText}</p>
            </div>
        );
    }

    render() {
        const { quantity, onChange, activity: { unitName, activityName, date }, error,
            shouldShowOverTrackingModal, isFieldVisible, isRemoveVisible, deleteActivity, i18n, fromSampleTask,
            isLoading, trackButtonLabel, isEditingActivityLog } = this.props;
        return (
            <form className={css(styles.container, isEditingActivityLog ? styles.smallerContainerWidth : {})} onSubmit={this.onFormSubmit} noValidate={true}>
                <div className={css(styles.body)}>
                    <div className={css(styles.header, layoutStyle.flex0, layoutStyle.flex)}>
                        <div className={css(layoutStyle.flexRowCenter)}>
                            <p className={css(styles.activityNameBold)}>{activityName}</p>
                            <Core.Icon
                                type="fa"
                                name="circle"
                                fill="solid"
                                size={spacing.s0}
                                color={baseColors.grey20}
                                className={css(styles.circleIcon)}
                            />
                            <p className={css(styles.activityDay)}>{overTrackedDateFormatted(date, i18n)}</p>
                        </div>
                        <Core.CloseButton id="closeBtnOnTrackingModal" onClick={this.dismissModal} />
                    </div>
                    {isFieldVisible ? (
                        <Core.UnitField
                            id="unitInput"
                            autoFocus={true}
                            fullWidth={true}
                            textFieldClassName={shouldShowOverTrackingModal && css(styles.overTrackingField)}
                            noTopMargin={true}
                            value={quantity}
                            inputProps={{ maxLength: UNIT_FIELD_LENGTH/*, type: 'number'*/ }}
                            onChangeText={onChange}
                            unitOptions={[unitName]}
                            unitsRegularFont={true}
                            unit={unitName}
                            inputClassName={styles.inputClassName}
                            error={!!error}
                            helperText={error}
                            inputHelper={shouldShowOverTrackingModal && this.warning}
                        />
                    ) : null
                    }
                </div>
                <div className={css(styles.buttonContainer)}>
                    {isRemoveVisible ? (
                        <Core.Button
                            id="removeBtnOnTrackingModal"
                            className={css(styles.deleteButton)}
                            onPress={deleteActivity}>
                            {i18n.t('remove')}
                        </Core.Button>
                    ) : null}
                    <Core.Button
                        id="trackBtnOnTrackingModal"
                        className={css(fromSampleTask ? styles.addButton : styles.trackButton)}
                        isSubmit={true}
                        isLoading={isLoading}
                        disabled={isLoading || !quantity || !!error}>
                        {trackButtonLabel || i18n.t('track')}
                    </Core.Button>
                </div>
            </form>
        );
    }
}

export default withRouter(WithActivityTrackingModalBase(ActivityTrackingModal));

const TRACKING_MODAL_WIDTH = 400;

const styles = StyleSheet.create({
    ...baseStyles,
    ...importantStyles({
        deleteButton: baseStyles.deleteButton,
        trackButton: baseStyles.trackButton,
        addButton: baseStyles.addButton
    }),
    smallerContainerWidth: {
        width: TRACKING_MODAL_WIDTH
    },
    warningWrapper: {
        display: 'flex',
        justifyContent: 'flex-start',
    },
    overTrackingField: importantClass({
        backgroundColor: baseColors.warnLightest,
        border: '1px solid',
        borderColor: baseColors.warn,
    }),
    inputClassName: importantClass({
        marginTop: spacing.s1,
        marginBottom: spacing.s1
    })
});
