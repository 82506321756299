import _ from 'lodash';
import moment from 'moment';
import * as types from './actionTypes';
import * as api from './api';
import * as selectors from './selectors';
import { EXTERNAL_SERVICES, LOGIN_STATUSES, USER_REGISTRATION_STEPS, MYMEDIBANK_AGE_ERROR, USER_REGISTRATION_STATUS, TELUS_SSO_TYPE, HRIS_ID_STATUS, SAML_SSO_STATUSES } from './constants';
import {
    Storage, actions as coreActions, selectors as coreSelectors, constants as coreConstants, Platform, PLATFORMS,
    healthKit, crypto, tracker, encodeQueryData, Alert, BUILDS } from '../core';
import { actions as rewardActions } from '../rewards';
import global from '../../config/globals';
import i18n from '../../i18n/i18n';
import { actions as notificationsActions, selectors as notificationsSelectors } from '../notifications';
import { actions as onboardingActions,
    selectors as onboardingSelectors,
    constants as onboardingConstants } from '../onboarding';
import { actions as appsDevicesActions } from '../appsdevices';
import { actions as challengesActions } from '../challenges';
import { actions as settingsActions } from '../settings';
import { actions as commentsLikeActions } from '../commentslikes';
import { services as watchServices } from '../watch';
import systemBuildVariant from '../../config/buildVariant';

import { getLightenColor, updateColors } from '../../styles/commonColor';

const CHANGE_PASSWORD_LOGOUT_DELAY = 5000;
const LAST_ACCOUNT_LOGOUT_DELAY = 2000;
const MYMEDIBANK_AGE_ERROR_DELAY = 1000;

export function loadInitData() {
    return function (dispatch, getState) {
        return dispatch(coreActions.loadInitData())
            .then(() => {
                const { partnerId, companyId, partnerSubdomain: programName } = coreSelectors.getCurrentUser(getState()) || {};
                if (programName) dispatch(getProgramDetails({ programName }));
                return Promise.all([
                    dispatch(getCompanyConfiguration({ partnerId, companyId })),
                    dispatch(onboardingActions.getUserTasks())
                ]);
            })
            .catch(error => {
                dispatch(loginError(error, false));
            });
    };
}

// required data after login
export function getAllData() {
    return function (dispatch, getState) {
        const { partnerSubdomain: programName, userId } = coreSelectors.getCurrentUser(getState()) || {};
        const companyName = coreSelectors.getCurrentUserCompanyName(getState());
        const partnerName = coreSelectors.getCurrentUserPartnerName(getState());
        const isLiveBetter = coreSelectors.isLiveBetter(getState());
        const onboardingSteps = onboardingSelectors.getOnboardingSteps(getState());
        const isHealthSurveyEnabled = coreSelectors.getIsHealthSurveyEnabled(getState());
        if (programName) dispatch(getProgramDetails({ programName }));
        dispatch(challengesActions.getActivityUnits());
        dispatch(challengesActions.getActivityLogs());
        if (userId) dispatch(settingsActions.getCurrentUserPrivacySettings(userId));
        dispatchOnboardingActions(dispatch, onboardingSteps);
        dispatch(notificationsActions.getUnreadCount());
        dispatch(notificationsActions.getAllNotifications());
        dispatch(commentsLikeActions.getReactionEmojis());
        dispatch(getCompanyExternalApps());
        dispatch(getPolicyPopups());
        if (!isLiveBetter) {
            dispatch(checkSmartModeStatus(userId));
        }
        if (isHealthSurveyEnabled) dispatch(onboardingActions.getHealthSurveyQuestions());
        window.dataLayer = window.dataLayer || [];
        //if this.props.companyId === 0 - probably not set Company_Name
        window.dataLayer.push({
            userId: `${userId}`,
            Company_Name: companyName,
            Partner_Name: partnerName,
            Is_Live_Better: isLiveBetter ? 'liveBetter': 'not livebetter'
        });
        tracker.setUserProperties({
            Company_Name: companyName,
            Partner_Name: partnerName,
            Is_Live_Better: isLiveBetter ? 'liveBetter from firebase': 'not livebetter from firebase'
        });
        tracker.setUserId(`${userId}`);
    };
}

function dispatchOnboardingActions(dispatch, onboardingSteps) {
    const { appsDevices, chooseGoal } = onboardingConstants.FLOW_STEPS;
    dispatch(rewardActions.getRewards());
    _.forEach(onboardingSteps, step => {
        switch (step) {
            case appsDevices:
                dispatch(appsDevicesActions.getAppsDevices());
                break;
            case chooseGoal:
                dispatch(challengesActions.getStarterGoals());
                break;
            default:
                break;
        }
    });
}

export function saveSystemEnvironment(environment) {
    return function (dispatch) {
        Storage.setItem('systemEnvironment', environment);
        // Remove saved endpoints once we rotate to new env
        Storage.removeItem('environmentEndpoints');
    };
}

export function saveEnvironmentEndpoints(environmentEndpoints) {
    return function (dispatch) {
        Storage.setItem('environmentEndpoints', environmentEndpoints);
    };
}

export function login(params, isToast = false, isGeneral = false) {
    return async function (dispatch) {
        dispatch(startLogin());
        if (systemBuildVariant !== BUILDS.mylivebetter) {
            const email = params.username || params.email;
            const error = await queryDirectoryInternal(dispatch, 'user', global.systemEnvironment.environment, email, true);
            if (error) {
                dispatch(loginError(error, isToast));
                return;
            }
        }
        dispatch(loginInternal(params, isToast, isGeneral));
    };
}

export function loginInternal(params, isToast = false, isGeneral = false) {
    return function (dispatch) {
        dispatch(startLogin());
        if (params.ssotype) {
            dispatch(updateUserSSOInfo(params));
        }
        return api.login(params)
            .then(res => {
                if (isGeneral) {
                    const queryParams = {
                        jwtLoginToken: res.data.jwtLoginToken,
                        username: params.username
                    };
                    const urlParams = `&${encodeQueryData(queryParams)}`;
                    window.location = `${res.data.siteUrl}/signout${urlParams}`;
                    //window.location = `http://localhost:3000/signout${urlParams}`;
                }
                const login_info = res.data;
                const loginUser = { login_info };
                Storage.setGenericSecureItem('loginUser', loginUser);
                Storage.setItem('rememberMe', Platform.OS === PLATFORMS.web ? !!params.rememberMe : true);
                dispatch(relogin(login_info));
            })
            .catch(error => {
                dispatch(loginError(error, isToast));
            });
    };
}

function startLogin() {
    return { type: types.LOGIN.REQUEST };
}

export function loginSuccess(data) {
    return function (dispatch) {
        dispatch({ type: types.LOGIN.SUCCESS });
        // added to fix stucking loading indicator (real problem is RN Modal)
        setTimeout(() => dispatch({ type: types.SET_LOGIN_INFO, payload: data }));
    };
}

export function loginError(error, isToast) {
    let msg = null;
    const errorCode = _.get(error.response, 'data.errorCode');
    if (errorCode === 'account_not_found') {
        msg = i18n.t('signIn.accountMayNotExist');
    }
    return {
        type: types.LOGIN.ERROR,
        payload: { error, isErrorStore: true, toast: isToast ? { title: i18n.t('signin'), msg } : undefined }
    };
}

export function clearLoginError() {
    return function (dispatch) {
        dispatch(coreActions.removeError(types.LOGIN.NAME));
    };
}

function _loginErrorRegistration(data) {
    return {
        type: types.LOGIN_ERROR_REGISTRATION,
        payload: { data }
    };
}

export function updateRememberEmail(username, rememberMe) {
    return function (dispatch) {
        dispatch({
            type: types.UPDATE_REMEMBER_EMAIL,
            payload: { username, rememberMe }
        });
    };
}

export function toggleRememberMe() {
    return function (dispatch) {
        dispatch({
            type: types.TOGGLE_REMEMBER_ME
        });
    };
}

export function relogin(login_info = {}, isFromLogin = false) {
    return async function (dispatch, getState) {
        _setGlobalsOnLoginLogout(login_info);
        if (login_info.registration) {
            await dispatch(getCompanyConfiguration(
                { partnerId: login_info.partnerId, companyId: login_info.companyId }));
            dispatch(_loginErrorRegistration(login_info));
            return;
        }
        api.setIntercept401Unauthorized(() => dispatch(logoutInternal()), () => selectors.getLoginStatus(getState()));
        await dispatch(loadInitData());
        dispatch(_refreshToken(true));
        dispatch(loginSuccess(login_info));
        dispatch(getAllData());
        dispatch(updatePushServiceConfiguration());
        dispatch(appsDevicesActions.initNativeApps());
    };
}

// This function is only used for migrating users with Mobile2 token to Mobile3
export function restoreLoginWithToken(token, expiry) {
    return async function (dispatch, getState) {
        global.token = token;
        api.setIntercept401Unauthorized(() => dispatch(logoutInternal()), () => selectors.getLoginStatus(getState()));
        await dispatch(loadInitData());
        const { partnerId, companyId, registration, isLocationSet, isVerified, role } = coreSelectors.getCurrentUser(getState()) || {};
        const programName = selectors.getCompanyProgramName(getState());
        const subdomain = selectors.getCompanySubdmain(getState());
        const login_info = {
            companyId,
            partnerId,
            programName,
            registration,
            isLocationSet,
            isVerified,
            role,
            subdomain,
            token,
            expirationDateTime: expiry.toISOString()
        };
        tracker.logEvent('login', { method: 'restoreMobile2', subdomain });
        const loginUser = { login_info };
        _setGlobalsOnLoginLogout(login_info);
        await Storage.setGenericSecureItem('loginUser', loginUser);
        Storage.setItem('rememberMe', true);
        dispatch(_refreshToken(true));
        dispatch(_setLoginStatus(LOGIN_STATUSES.loggedIn));
        dispatch(getAllData());
        dispatch(updatePushServiceConfiguration());
    };
}

export function restoreLogin(login_info) {
    return async function (dispatch, getState) {
        _setGlobalsOnLoginLogout(login_info);
        api.setIntercept401Unauthorized(() => dispatch(logoutInternal()), () => selectors.getLoginStatus(getState()));
        dispatch(_refreshToken(login_info.rememberMe));
        await dispatch(loadInitData());
        dispatch(_setLoginStatus(LOGIN_STATUSES.loggedIn));
        dispatch(getAllData());
        dispatch(updatePushServiceConfiguration());
        dispatch(appsDevicesActions.initNativeApps());
    };
}

export function updatePushServiceConfiguration() {
    return function (dispatch, getState) {
        const isOnboarding = _.get(coreSelectors.getCurrentUser(getState()), 'onboardingIncomplete', false);
        if (!isOnboarding) {
            dispatch(notificationsActions.requestPushPermissions());
        }
    };
}

export function setLoginInformationAfterVerification(login_info) {
    return async function (dispatch, getState) {
        const loginUser = { login_info };
        _setGlobalsOnLoginLogout(login_info);
        await Storage.setGenericSecureItem('loginUser', loginUser);
        Storage.setItem('rememberMe', true);
        api.setIntercept401Unauthorized(() => dispatch(logoutInternal()), () => selectors.getLoginStatus(getState()));
        await dispatch(loadInitData());
        dispatch(loginSuccess(login_info));
        dispatch(getAllData());
        dispatch(completeRegistration());
    };
}

function _setGlobalsOnLoginLogout(login_info) {
    global.token = login_info.token;
    global.systemSubdomain = login_info.subdomain ? login_info.subdomain : login_info.programName; // can be un-turnaried once we are sure the backend change is on live
    global.programName = login_info.fullProgramName ? login_info.fullProgramName : login_info.programName;

    if (Platform.OS === PLATFORMS.ios) watchServices.appleWatchHelper.sendAuthTokenAndSubdomainToWatch();
}

function _setLoginStatus(loginStatus) {
    return function (dispatch) {
        dispatch({
            type: types.SET_LOGIN_STATUS,
            payload: loginStatus
        });
    };
}

function _refreshToken(rememberMe) {
    return function (dispatch) {
        return api.refreshToken(rememberMe);
    };
}

export function logout(delay = 0) {
    return function (dispatch, getState) {
        dispatch({ type: types.SIGNOUT.REQUEST });
        dispatch(notificationsActions.resetPushNotificationPermissions());
        dispatch(notificationsActions.unregisterDeviceForPush()).finally(() => {
            dispatch({ type: types.SIGNOUT.SUCCESS });
            dispatch(logoutInternal(delay));
        });
    };
}

export function logoutAndResetState() {
    return function (dispatch, getState) {
        dispatch({ type: types.LOGOUT });
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({ userId: undefined, Company_Name: undefined, Partner_Name: undefined, Is_Live_Better: undefined });
        dispatch(coreActions.resetState());
        const partnerSubdomain = coreSelectors.getPartnerSubdomain(getState());
        partnerSubdomain && !coreSelectors.isGeneralLoginPage(getState()) && dispatch(getProgramConfigurations({ programName: partnerSubdomain }));
    };
}

export function logoutInternal(delay) {
    return async function (dispatch) {
        try {
            if (global.token) {
                await api.logout();
            }
            // eslint-disable-next-line no-empty
        } catch (e) { }
        api.eject();
        _setGlobalsOnLoginLogout({ systemSubdomain: global.originalSystemSubdomain, token: '', programName: '' });
        await Storage.resetGenericSecureItem('loginUser');
        Storage.removeItem('rememberMe');
        if (delay) setTimeout(() => dispatch(logoutAndResetState()), delay);
        else dispatch(logoutAndResetState());
        dispatch(clearAppleHealthBackgroundSync());
    };
}

export function internalStateReset() {
    return function (dispatch) {
        dispatch({ type: types.INTERNAL_STATE_RESET });
        dispatch(coreActions.resetState());
    };
}

// This function is used when we need to send the user back to the Welcome screen while logging in/signing up.
export function restartApp() {
    return function (dispatch) {
        dispatch(_setLoginStatus(LOGIN_STATUSES.stateReset));
        dispatch(_setLoginStatus(LOGIN_STATUSES.loggedOut));
    };
}

export function updateUserSSOInfo(ssoParams) {
    const params = { ...ssoParams, password: crypto.md5(ssoParams.token).toString() };
    if (Platform.OS === PLATFORMS.web) Storage.setItem('rememberMe', true);
    return {
        type: types.SET_USER_SSO.SUCCESS,
        payload: params
    };
}

export function setSSOError(error = {}) {
    return {
        type: types.SET_USER_SSO.ERROR,
        payload: { error, toast: { actionType: 'signUpPartnerSSO' } }
    };
}

export function clearUserSSOInfo() {
    return { type: types.CLEAR_USER_SSO };
}

export function checkGUIDStatus(guid, params) {
// Call directory service on params.program
    return async function (dispatch) {
        if (systemBuildVariant !== BUILDS.mylivebetter && _.get(params, 'programName')) {
            const programName = _.trim(_.toLower(params.programName));
            global.systemSubdomain = programName;
            const error = await queryDirectoryInternal(dispatch, 'program', global.systemEnvironment.environment, programName, false);
            if (error) {
                dispatch(getCompanyConfigurationError(error, true));
                return;
            }
        }
        dispatch(checkGUIDStatusInternal(guid, params));
    };
}

export function checkGUIDStatusInternal(guid, params) {
    return function (dispatch, getState) {
        dispatch({ type: types.CHECK_SAML_STATUS.REQUEST });
        return api.checkGUIDStatus(guid)
            .then(res => {
                if ((res.data)) {
                    global.token = res.data;
                    const token = global.token;
                    api.setIntercept401Unauthorized(() => dispatch(logoutInternal()), () => selectors.getLoginStatus(getState()));
                    const status = _.get(params, 'status');
                    dispatch(loadInitData()).then(() => {
                        const { partnerId, companyId, registration, isLocationSet, isVerified, role } = coreSelectors.getCurrentUser(getState()) || {};
                        const programName = selectors.getCompanyProgramName(getState());
                        const subdomain = selectors.getCompanySubdmain(getState()) ? selectors.getCompanySubdmain(getState()) : programName;
                        const login_info = {
                            companyId,
                            partnerId,
                            programName,
                            registration,
                            isLocationSet,
                            isVerified,
                            role,
                            subdomain,
                            token
                        };
                        tracker.logEvent('login', { method: 'sso', subdomain });
                        const loginUser = { login_info };
                        _setGlobalsOnLoginLogout(login_info);
                        Storage.setGenericSecureItem('loginUser', loginUser);
                        Storage.setItem('rememberMe', true);
                        dispatch(_refreshToken(true));
                        if (status && status !== SAML_SSO_STATUSES.registration) {
                            dispatch(loginSuccess(login_info));
                        }
                    });

                    dispatch(getSSODetailsForCurrentUser())
                        .then(() => {
                            dispatch({ type: types.CHECK_SAML_STATUS.SUCCESS, payload: { } });
                            dispatch(getAllData());
                            dispatch(updatePushServiceConfiguration());
                            dispatch(appsDevicesActions.initNativeApps());
                        });
                }
            })
            .catch(error => {
                const err = { displayMessage: i18n.t('authnetication.saml.error') };
                dispatch({ type: types.CHECK_SAML_STATUS.ERROR,
                    payload: { err, toast: { type: coreConstants.TOAST_TYPES.DANGER, actionType: '' } }
                });
            });
    };
}

export function getSSODetailsForCurrentUser(data) {
    return function (dispatch) {
        dispatch({ type: types.SET_SSO_DETAILS_FROM_CURRENT_USER.REQUEST });
        return api.getUser()
            .then(response => {
                const formattedData = {};
                formattedData.email = _.get(response.data, 'email');
                formattedData.firstName = _.get(response.data, 'firstName');
                formattedData.lastName = _.get(response.data, 'lastName');
                dispatch({ type: types.SET_SSO_DETAILS_FROM_CURRENT_USER.SUCCESS, payload: { data: formattedData } });
            })
            .catch(error => {
                dispatch({ type: types.SET_SSO_DETAILS_FROM_CURRENT_USER.ERROR,
                    payload: { error }
                });
            });
    };
}

export function getSSODetailsForCurrentUserSuccess(data) {
    const formattedData = {};
    formattedData.email = _.get(data, 'email');
    formattedData.firstName = _.get(data, 'firstName');
    formattedData.lastName = _.get(data, 'lastName');
    return {
        type: types.SET_SSO_DETAILS_FROM_CURRENT_USER.SUCCESS,
        payload: {
            data: formattedData
        }
    };
}

export function clearCheckSamlStatusError() {
    return function (dispatch) {
        dispatch(coreActions.removeError(types.GET_EMAIL_PROGRAM_NAME.NAME));
    };
}

export function loginFromSSORegistration(params) {
    return function (dispatch) {
        dispatch(startLogin());
        // api.setValidateStatus(status => (status >= 200 && status < 300) || status === 404 || status === 400);
        return dispatch(updateUser({ currentRegistrationStep: USER_REGISTRATION_STEPS.none }))
            .then(() => {
                if (params.ssotype === EXTERNAL_SERVICES.saml) {
                    dispatch(relogin(params)).then(() => dispatch(completeRegistration()));
                } else {
                    dispatch(login(params))
                        .then(() => {
                            dispatch(coreActions.retrieveCurrentUserInfo());
                            dispatch(completeRegistration());
                        });
                }
            })
            .catch(error => {
                console.log('loginFromRegistration', error);
            });
    };
}

export function ssoLinkUser(params) {
    return function (dispatch, getState) {
        dispatch({ type: types.LINK_EXTERNAL_ACCOUNT.REQUEST });
        return api.ssoLinkUser(params)
            .then(res => {
                dispatch(ssoLinkUserSuccess(res.data, params.ssotype));
                dispatch(coreActions.retrieveCurrentUserInfo()); // update the core values
                dispatch(getUser()); // update auth values
            }).catch(error => {
                const isLiveBetter = coreSelectors.isLiveBetter(getState());
                const errorCode = _.get(error.response.data, 'errorCode');
                if (errorCode === MYMEDIBANK_AGE_ERROR && isLiveBetter) {
                    dispatch(linkExternalAccountError(error, params.ssotype, false));
                    setTimeout(() => Alert.alert('', i18n.t('mymedibank.underAge.error'), [{ text: i18n.t('ok') }]), MYMEDIBANK_AGE_ERROR_DELAY);
                } else {
                    dispatch(linkExternalAccountError(error, params.ssotype, true));
                }
            });
    };
}

export function generateCorporateAccountLinkEmail(params, toast) {
    return function (dispatch) {
        dispatch({ type: types.GENERATE_CORPORATE_ACCOUNT_LINK_EMAIL.REQUEST });
        return api.generateCorporateAccountLinkEmail(params)
            .then(res => {
                dispatch({
                    type: types.GENERATE_CORPORATE_ACCOUNT_LINK_EMAIL.SUCCESS,
                    payload: { toast }
                });
            }).catch(error => {
                dispatch({
                    type: types.GENERATE_CORPORATE_ACCOUNT_LINK_EMAIL.ERROR,
                    payload: { error, toast: { actionType: 'accountLinking' } }
                });
            });
    };
}

export function linkCorporateAccount(params) {
    return function (dispatch) {
        dispatch({ type: types.LINK_CORPORATE_ACCOUNT.REQUEST });
        return api.linkCorporateAccount(params)
            .then(res => {
                dispatch(linkCorporateAccountSuccess(res.data));
                dispatch(handleLinking());
            }).catch(error => {
                dispatch(linkCorporateAccountError(error));
            });
    };
}

export function handleLinking() {
    return async function (dispatch) {
        await dispatch(loadInitData());
        dispatch(getUser());
    };
}


export function linkCorporateAccountSuccess(data, ssotype=EXTERNAL_SERVICES.corporate) {
    const msg = _.get(data, 'updatedUserInfo', []).includes('email')
        ? `${i18n.t('accountLinkSuccessful')} ${i18n.t('accountLinkEmailChanged')}` : undefined;
    return {
        type: types.LINK_CORPORATE_ACCOUNT.SUCCESS,
        payload: {
            accountLinkType: ssotype,
            toast: { actionType: 'accountLinking', msg }
        }
    };
}

export function linkCorporateAccountError(error, title) {
    return {
        type: types.LINK_CORPORATE_ACCOUNT.ERROR,
        payload: { error, toast: { actionType: 'accountLinking', title } }
    };
}

export function ssoLinkUserSuccess(data, ssotype) {
    return function (dispatch) {
        dispatch(linkExternalAccountSuccess(data, ssotype));
        dispatch(rewardActions.getRewards()); // fetch rewards to see if user is now eligible
    };
}

export function linkExternalAccountSuccess(data, ssotype) {
    const msg = _.get(data, 'updatedUserInfo', []).includes('email')
        ? `${i18n.t('accountLinkSuccessful')} ${i18n.t('accountLinkEmailChanged')}` : undefined;
    return {
        type: types.LINK_EXTERNAL_ACCOUNT.SUCCESS,
        payload: {
            accountLinkType: ssotype,
            toast: { actionType: 'accountLinking', msg, title: ssotype }
        }
    };
}

// dispatched in multiple places
export function linkExternalAccountError(error, title, isToast = true) {
    return {
        type: types.LINK_EXTERNAL_ACCOUNT.ERROR,
        payload: { error, toast: isToast ? { actionType: 'accountLinking', title } : undefined }
    };
}

export function disconnectExternalAccount(params, isLast) {
    const actionType = 'accountUnlinking';
    return function (dispatch, getState) {
        const email = coreSelectors.getCurrentUser(getState()).email;
        dispatch({ type: types.UNLINK_EXTERNAL_ACCOUNT.REQUEST });
        return api.disconnectExternalAccount(params)
            .then(() => isLast && api.resetPassword(email))
            .then(() => {
                dispatch({
                    type: types.UNLINK_EXTERNAL_ACCOUNT.SUCCESS,
                    payload: { accountLinkType: params.ssotype, toast: { actionType, title: params.ssotype } }
                });
            })
            .then(() => isLast && dispatch(logout(LAST_ACCOUNT_LOGOUT_DELAY)))
            .catch(error => {
                dispatch({
                    type: types.UNLINK_EXTERNAL_ACCOUNT.ERROR,
                    payload: { error, toast: { actionType, title: params.ssotype } }
                });
            });
    };
}

export function unlinkCorporateAccount(params, isLast) {
    const actionType = 'accountUnlinking';
    return async function (dispatch, getState) {
        const email = coreSelectors.getCurrentUser(getState()).email;
        dispatch({ type: types.UNLINK_CORPORATE_ACCOUNT.REQUEST });
        const deviceId = notificationsSelectors.getPushNotificationsDeviceId(getState());
        await dispatch(notificationsActions.unregisterDeviceForPush());
        return api.unlinkCorporateAccount({})
            .then(() => isLast && api.resetPassword(email))
            .then(() => {
                dispatch({
                    type: types.UNLINK_CORPORATE_ACCOUNT.SUCCESS,
                    payload: {
                        accountLinkType: EXTERNAL_SERVICES.corporate,
                        toast: { actionType }
                    }
                });
                dispatch(logoutInternal());
            })
            .catch(error => {
                // Reregister the device so we can get push stuff
                dispatch(notificationsActions.registerDeviceForPush(deviceId));
                dispatch({
                    type: types.UNLINK_CORPORATE_ACCOUNT.ERROR,
                    payload: { error, toast: { actionType, msg: false } }
                });
            });
    };
}

export function getCompanyConfiguration(params) {
    return function (dispatch, getState) {
        dispatch({ type: types.GET_COMPANY_CONFIGURATION.REQUEST });
        return api.getCompanyConfiguration(params)
            .then(res => {
                dispatch(getCompanyConfigurationSuccess(res.data));
                if (Platform.OS !== PLATFORMS.web) {
                    dispatch(updateWhiteLabelConfiguration(_.get(res.data, 'brand', {})));
                }
                const { partnerId } = coreSelectors.getCurrentUser(getState()) || {};
                if (partnerId) {
                    // If the partnerId is not available, it probably means the current user is not available either
                    // Most likely in a user-not verified state when signing up, calling getFeedbackMetadata will
                    // return 401 in this state.
                    dispatch(settingsActions.getFeedbackMetadata(partnerId));
                }
            })
            .catch(error => {
                dispatch(getCompanyConfigurationError(error));
            });
    };
}

export function updateWhiteLabelConfiguration(brand) {
    return function (dispatch) {
        if (brand) {
            const { primaryColor: primary, secondaryColor: secondary, backgroundColor: background } = brand;
            updateColors(_.pickBy({ primary, secondary, background, dashboardLeftGradient: primary, dashboardRightGradient: primary, primaryLighten: getLightenColor(primary, 0.1) }, _.identity));
        }
    };
}

async function queryDirectoryInternal(dispatch, resource, env, key, isEmail = false) {
    if (isEmail) {
        try {
            const formattedEmail = _.toLower(_.trim(_.replace(key, '+', '%2B')));
            const emailParts = _.split(formattedEmail, '@');
            const emailDomain = emailParts[1];
            const emailHash = crypto.hmacsha256(formattedEmail, 'sprout').toString();
            const domainHash = crypto.hmacsha256(emailDomain, 'sprout').toString();
            dispatch({ type: types.QUERY_GLOBAL_DIRECTORY.REQUEST });
            const response = await api.queryDirectoryEmail(resource, env, emailHash, domainHash);
            const endpoints = response.data;
            dispatch({ type: types.QUERY_GLOBAL_DIRECTORY.SUCCESS });
            const environmentEndpoints = { environment: global.systemEnvironment.environment, ...endpoints };
            global.systemEnvironment = environmentEndpoints;
            dispatch(saveEnvironmentEndpoints(environmentEndpoints));
        }
        catch (error) {
            dispatch({ type: types.QUERY_GLOBAL_DIRECTORY.ERROR });
            return error;
        }
        return false;
    } else {
        try {
            dispatch({ type: types.QUERY_GLOBAL_DIRECTORY.REQUEST });
            const response = await api.queryDirectory(resource, env, key);
            const endpoints = response.data;
            dispatch({ type: types.QUERY_GLOBAL_DIRECTORY.SUCCESS });
            const environmentEndpoints = { environment: global.systemEnvironment.environment, ...endpoints };
            global.systemEnvironment = environmentEndpoints;
            dispatch(saveEnvironmentEndpoints(environmentEndpoints));
        }
        catch (error) {
            dispatch({ type: types.QUERY_GLOBAL_DIRECTORY.ERROR });
            return error;
        }
        return false;
    }
}

export function getProgramConfigurations(params = {}) {
    return async function (dispatch) {
        if (systemBuildVariant !== BUILDS.mylivebetter) {
            const programName = _.trim(_.toLower(params.programName));
            const error = await queryDirectoryInternal(dispatch, 'program', global.systemEnvironment.environment, programName, false);
            if (error) {
                dispatch(getCompanyConfigurationError(error, true));
                return;
            }
        }
        dispatch(getProgramConfigurationsInternal(params));
    };
}

function getProgramConfigurationsInternal(params = {}) {
    return function (dispatch) {
        dispatch({ type: types.GET_COMPANY_CONFIGURATION.REQUEST });
        return api.getProgramConfigurations(params)
            .then(res => {
                if (res.status === 200) {
                    if (!params.pin) {
                        dispatch(getProgramDetailsSuccess(res.data));
                    }
                    const { programName: enteredProgramName, pin: enteredProgramPin } = params;
                    dispatch(getCompanyConfigurationSuccess(
                        { ...res.data, enteredProgramName, enteredProgramPin }, !!params.pin));
                }
                else {
                    // There is code that treats 404 as success that I am too afraid to take out for now
                    // So it's manually checked..
                    dispatch(getCompanyConfigurationError(res, true));
                }
            })
            .catch(error => {
                dispatch(getCompanyConfigurationError(error, true));
            });
    };
}

export function getCompanyConfigurationSuccess(data = {}, isPin) {
    return {
        type: types.GET_COMPANY_CONFIGURATION.SUCCESS,
        payload: { data, isPin }
    };
}

export function getCompanyConfigurationError(error, isErrorStore) {
    return {
        type: types.GET_COMPANY_CONFIGURATION.ERROR,
        payload: { error, isErrorStore }
    };
}

export function getProgramDetails(params) {
    return function (dispatch) {
        dispatch({ type: types.GET_PROGRAM_DETAILS.REQUEST });
        return api.getProgramConfigurations(params)
            .then(res => dispatch(getProgramDetailsSuccess(res.data)))
            .catch(error => dispatch({ type: types.GET_PROGRAM_DETAILS.ERROR, payload: { error } }));
    };
}

export function getProgramDetailsSuccess(data) {
    return {
        type: types.GET_PROGRAM_DETAILS.SUCCESS,
        payload: { data }
    };
}

export function clearCompanyConfiguration() {
    return {
        type: types.CLEAR_COMPANY_CONFIGURATION
    };
}

export function clearCompanyConfigurationError() {
    return function (dispatch) {
        dispatch(coreActions.removeError(types.GET_COMPANY_CONFIGURATION.NAME));
    };
}

export function resetCompanyConfiguration() {
    return function (dispatch, getState) {
        dispatch(clearCompanyConfiguration());
        dispatch(getProgramConfigurations({ programName: selectors.getProgram(getState()) }));
    };
}

export function getEmailProgramName(email) {
    return async function (dispatch) {
        if (systemBuildVariant !== BUILDS.mylivebetter) {
            dispatch({ type: types.GET_EMAIL_PROGRAM_NAME.REQUEST });
            const error = await queryDirectoryInternal(dispatch, 'user', global.systemEnvironment.environment, email, true);
            if (error) {
                dispatch({ type: types.GET_EMAIL_PROGRAM_NAME.ERROR, payload: { error, isErrorStore: true } });
                return;
            }
        }
        dispatch(getEmailProgramNameInternal(email));
    };
}

function getEmailProgramNameInternal(email) {
    return function (dispatch) {
        dispatch({ type: types.GET_EMAIL_PROGRAM_NAME.REQUEST });
        return api.getEmailProgramName(email)
            .then(res => {
                dispatch({
                    type: types.GET_EMAIL_PROGRAM_NAME.SUCCESS,
                    payload: {
                        data: res.data,
                        toast: {
                            msg: i18n.t('findProgramSuccessMessage'),
                            title: i18n.t('findProgramSuccessTitle')
                        }
                    }
                });
            }).catch(error => {
                dispatch({
                    type: types.GET_EMAIL_PROGRAM_NAME.ERROR,
                    payload: { error, isErrorStore: true }
                });
            });
    };
}

export function clearEmailProgramNameError() {
    return function (dispatch) {
        dispatch(coreActions.removeError(types.GET_EMAIL_PROGRAM_NAME.NAME));
    };
}

export function getTermsOfService(params) {
    return function (dispatch) {
        dispatch({ type: types.GET_TERMS_OF_SERVICE.REQUEST });
        return api.getTermsOfService(params)
            .then(res => {
                dispatch({ type: types.GET_TERMS_OF_SERVICE.SUCCESS, payload: { data: res.data } });
            })
            .catch(error => {
                dispatch({ type: types.GET_TERMS_OF_SERVICE.ERROR, payload: { error } });
            });
    };
}

export function getPrivacyPolicy(params) {
    return function (dispatch) {
        dispatch({ type: types.GET_PRIVACY_POLICY.REQUEST });
        return api.getPrivacyPolicy(params)
            .then(res => {
                dispatch({ type: types.GET_PRIVACY_POLICY.SUCCESS, payload: { data: res.data } });
            })
            .catch(error => {
                dispatch({ type: types.GET_PRIVACY_POLICY.ERROR, payload: { error } });
            });
    };
}

export function getPolicyPopups() {
    return function (dispatch) {
        dispatch({ type: types.GET_POLICY_POPUPS.REQUEST });
        return api.getPolicyPopups()
            .then(res => {
                dispatch({ type: types.GET_POLICY_POPUPS.SUCCESS, payload: { data: res.data } });
            })
            .catch(error => {
                dispatch({ type: types.GET_POLICY_POPUPS.ERROR, payload: { error } });
            });
    };
}

export function markUpdatedPolicyAsSeen(policy) {
    return function (dispatch) {
        dispatch({ type: types.MARK_POLICY_UPDATE_SEEN.REQUEST });
        return api.markPolicySeen({ string_id: policy.string_id })
            .then(res => {
                dispatch({ type: types.MARK_POLICY_UPDATE_SEEN.SUCCESS, payload: { data: res.data } });
                dispatch(getPolicyPopups());
            })
            .catch(error => {
                dispatch({ type: types.MARK_POLICY_UPDATE_SEEN.ERROR, payload: { error } });
            });
    };
}

export function getDisclaimer(params) {
    return function (dispatch) {
        dispatch({ type: types.GET_DISCLAIMER.REQUEST });
        return api.getDisclaimer(params)
            .then(res => {
                dispatch({ type: types.GET_DISCLAIMER.SUCCESS, payload: { data: res.data } });
            })
            .catch(error => {
                dispatch({ type: types.GET_DISCLAIMER.ERROR, payload: { error } });
            });
    };
}

export function createAccount(params, isTelus = false) {
    return function (dispatch, getState) {
        const ssotype = _.get(params, 'ssotype');
        let paramsToSend;
        if (ssotype === TELUS_SSO_TYPE) {
            const ssoDetails = selectors.getSsoDetails(getState());
            const password = _.get(ssoDetails, 'password', '');
            paramsToSend = { ...params, password };
        } else {
            const hrisId = selectors.getHrisId(getState());
            if (hrisId !== undefined) {
                paramsToSend = { ...params, hrisId };
            } else {
                paramsToSend = { ...params };
            }
        }
        dispatch({ type: types.CREATE_ACCOUNT.REQUEST });
        return api.createAccount({ ...paramsToSend, language: i18n.acceptLanguage() })
            .then(res => {
                if (!params.validateOnly) {
                    global.token = res.data.token;
                    dispatch(updateRememberEmail(params.email, true));
                }
                dispatch({ type: types.CREATE_ACCOUNT.SUCCESS, payload: { data: res.data } });
                if (isTelus) {
                    const companyId = _.get(params, 'company_id');
                    const partnerId = _.get(res.data, 'partnerId');
                    if (companyId && partnerId) {
                        dispatch(getCompanyConfiguration({ partnerId, companyId }));
                        dispatch(getLocationsList(companyId, partnerId));
                    }
                }
            })
            .catch(error => {
                const toast = { title: i18n.t('registration.signup.errorHeading') };
                dispatch({ type: types.CREATE_ACCOUNT.ERROR, payload: { error, isErrorStore: true, toast: isTelus ? toast : null } });
                const isLiveBetter = coreSelectors.isLiveBetter(getState());
                const errorCode = _.get(error, 'response.data.errorCode');
                if (errorCode === MYMEDIBANK_AGE_ERROR && isLiveBetter) {
                    setTimeout(() => Alert.alert('', i18n.t('mymedibank.underAge.error'), [{ text: i18n.t('ok'), onPress: () => dispatch(logoutUserAgeError()) }]), MYMEDIBANK_AGE_ERROR_DELAY);
                }
            });
    };
}

export function clearCreateAccountError() {
    return function (dispatch) {
        dispatch(coreActions.removeError(types.CREATE_ACCOUNT.NAME));
    };
}

export function deleteAccount() {
    return function (dispatch) {
        dispatch({ type: types.DELETE_ACCOUNT.REQUEST });
        return api.deleteAccount()
            .then(() => {
                dispatch({ type: types.DELETE_ACCOUNT.SUCCESS });
                dispatch(logout());
            })
            .catch(error => dispatch({
                type: types.DELETE_ACCOUNT.ERROR,
                payload: { error, toast: { title: i18n.t('deleteAccount') } }
            }));
    };
}

export function getUser() {
    return function (dispatch) {
        dispatch({ type: types.GET_USER.REQUEST });
        return api.getUser()
            .then(res => {
                dispatch(getUserSuccess(res.data));
            })
            .catch(error => {
                dispatch({ type: types.GET_USER.ERROR, payload: { error } });
            });
    };
}

function getUserSuccess(data) {
    return { type: types.GET_USER.SUCCESS, payload: { data } };
}

export function updateUser(params, toast) {
    return function (dispatch, getState) {
        // api.setValidateStatus(status => (status >= 200 && status < 300) || status === 404 || status === 400);
        dispatch({ type: types.UPDATE_USER.REQUEST });
        return api.updateUser(params)
            .then(res => {
                dispatch({ type: types.UPDATE_USER.SUCCESS, payload: { toast, data: res.data } });
            })
            .catch(error => {
                dispatch({ type: types.UPDATE_USER.ERROR, payload: { error } });
            });
    };
}

export function logoutUserAgeError() {
    return async function (dispatch) {
        await dispatch(logout());
        await dispatch(restartApp());
    };
}


export const changeLanguage = language => dispatch => {
    dispatch({ type: types.UPDATE_USER.REQUEST });
    return api.updateUser({ language })
        .then(res => {
            if (res.data.language) {
                i18n.changeLanguage(res.data.language.replace('_', '-'), err => {
                    if (err) return;
                    dispatch({ type: types.CHANGE_LANGUAGE, payload: { language } });
                    dispatch(coreActions.retrieveCurrentUserInfo());
                    dispatch(coreActions.getFilters());
                    dispatch(onboardingActions.getUserTasks());
                    dispatch(challengesActions.getActivityUnits());
                });
                const lang = res.data.language.replace('_', '-');
                if (moment.locales().includes(lang.toLowerCase())) {
                    moment.locale(lang.toLowerCase());
                } else if (moment.locales().includes(lang.substring(0, 2))) {
                    moment.locale(lang.substring(0, 2));
                } else {
                    moment.locale('en');
                }
            }
            dispatch({ type: types.UPDATE_USER.SUCCESS, payload: { data: res.data } });
        })
        .catch(error => {
            dispatch({ type: types.UPDATE_USER.ERROR, payload: { error } });
        });
};

// different actions is used for profile because current user should be updated and toast should be shown
export function saveUser(params) {
    const actionType = 'savingUser';
    return function (dispatch) {
        dispatch({ type: types.SAVE_USER.REQUEST });
        return api.updateUser(params)
            .then(res => {
                dispatch(getUserSuccess(res.data));
                dispatch(coreActions.retrieveCurrentUserInfo());
                dispatch({ type: types.SAVE_USER.SUCCESS, payload: { toast: { actionType } } });
            })
            .catch(error => {
                dispatch({ type: types.SAVE_USER.SUCCESS, payload: { error, toast: { actionType } } });
            });
    };
}

// load all needed data together
export function getUserLocations(companyId, partnerId=undefined) {
    return function (dispatch) {
        dispatch({ type: types.GET_USER_LOCATIONS.REQUEST });
        return api.getUserLocations()
            .then(res => {
                const currentLocation = _.find(res.data, location => location.current === true);
                const promises = [dispatch(getLocationsList(companyId, partnerId))];
                if (currentLocation && currentLocation.location_id) {
                    promises.push(dispatch(getDepartmentsList(companyId, currentLocation.location_id)));
                }
                return Promise.all(promises).then(() => dispatch(getUserLocationsSuccess(res.data, currentLocation)));
            })
            .catch(error => {
                dispatch({ type: types.GET_USER_LOCATIONS.ERROR, payload: { error } });
            });
    };
}

function getUserLocationsSuccess(data, currentLocation) {
    return { type: types.GET_USER_LOCATIONS.SUCCESS, payload: { data, currentLocation } };
}

export function getLocationsList(companyId, partnerId=undefined) {
    return function (dispatch) {
        dispatch({ type: types.GET_LOCATIONS.REQUEST });
        return api.getLocationsList(companyId, partnerId)
            .then(res => {
                dispatch({ type: types.GET_LOCATIONS.SUCCESS, payload: { data: res.data } });
            })
            .catch(error => {
                dispatch({ type: types.GET_LOCATIONS.ERROR, payload: { error, toast: {} } });
            });
    };
}

export function getDepartmentsList(companyId, locationId) {
    return function (dispatch) {
        if (!companyId) return;
        dispatch({ type: types.GET_DEPARTMENTS.REQUEST });
        return api.getDepartmentsList(companyId, locationId)
            .then(res => {
                dispatch({ type: types.GET_DEPARTMENTS.SUCCESS, payload: { data: res.data } });
            })
            .catch(error => {
                dispatch({ type: types.GET_DEPARTMENTS.ERROR, payload: { error } });
            });
    };
}

export function getRegions(companyId) {
    return function (dispatch) {
        if (!companyId) return;
        dispatch({ type: types.GET_REGIONS.REQUEST });
        return api.getRegions(companyId)
            .then(res => dispatch({ type: types.GET_REGIONS.SUCCESS, payload: { data: res.data } }))
            .catch(error => dispatch({ type: types.GET_REGIONS.ERROR, payload: { error } }));
    };
}

export function setUserLocationDepartment(location, department, isToast) {
    return function (dispatch) {
        const actionType = 'savingUser';
        if (location.location_id && !department.department_id) {
            dispatch({ type: types.SET_USER_LOCATIONS.ERROR, payload: { toast: { actionType } } });
            return;
        }
        dispatch({ type: types.SET_USER_LOCATIONS.REQUEST });
        return api.updateUser({ ...location, ...department })
            .then(() => api.createUserLocations(location))
            .then(() => api.updateUserLocations(location))
            .then(res => {
                dispatch(getUserLocationsSuccess(res.data));
                if (isToast) dispatch(coreActions.retrieveCurrentUserInfo());
                dispatch({
                    type: types.SET_USER_LOCATIONS.SUCCESS,
                    payload: { data: res.data, toast: isToast && { actionType } }
                });
            })
            .catch(error => {
                dispatch({ type: types.SET_USER_LOCATIONS.ERROR, payload: { toast: isToast && { actionType }, error } });
            });
    };
}

export function setUserLocation(location, isToast) {
    return function (dispatch) {
        const actionType = 'savingUser';
        dispatch({ type: types.SET_USER_LOCATIONS.REQUEST });
        return api.createUserLocations(location)
            .then(() => api.updateUserLocations(location))
            .then(res => {
                dispatch(getUserLocationsSuccess(res.data));
                if (isToast) dispatch(coreActions.retrieveCurrentUserInfo());
                dispatch({
                    type: types.SET_USER_LOCATIONS.SUCCESS,
                    payload: { data: res.data, toast: isToast && { actionType } }
                });
            })
            .catch(error => {
                dispatch({ type: types.SET_USER_LOCATIONS.ERROR, payload: { toast: isToast && { actionType }, error } });
            });
    };
}

export function updateUserLocationDepartment(location, department) {
    return function (dispatch) {
        const actionType = 'savingUser';
        if (location.location_id && !department.department_id) {
            dispatch({ type: types.SET_USER_LOCATIONS.ERROR, payload: { toast: { actionType } } });
            return;
        }
        dispatch({ type: types.SET_USER_LOCATIONS.REQUEST });
        return api.updateUser({ ...location, ...department })
            .then(() => api.updateUserLocations(location))
            .then(res => {
                dispatch(coreActions.retrieveCurrentUserInfo());
                dispatch({
                    type: types.SET_USER_LOCATIONS.SUCCESS,
                    payload: { data: res.data, toast: { actionType } }
                });
            })
            .catch(error => {
                dispatch({ type: types.SET_USER_LOCATIONS.ERROR, payload: { toast: { actionType }, error } });
            });
    };
}

export function updateUserLocation(location) {
    return function (dispatch) {
        const actionType = 'savingUser';
        dispatch({ type: types.SET_USER_LOCATIONS.REQUEST });
        return api.updateUserLocations(location)
            .then(res => {
                dispatch(coreActions.retrieveCurrentUserInfo());
                dispatch({
                    type: types.SET_USER_LOCATIONS.SUCCESS,
                    payload: { data: res.data, toast: { actionType } }
                });
            })
            .catch(error => {
                dispatch({ type: types.SET_USER_LOCATIONS.ERROR, payload: { toast: { actionType }, error } });
            });
    };
}

export function verifyEmail(isToast) {
    return function (dispatch, getState) {
        const successToast = {
            title: i18n.t('email_successfully_verified_title'),
            msg: i18n.t('email_successfully_verified')
        };
        const errorToast = {
            title: i18n.t('resendEmailTitle'),
            msg: i18n.t('email_not_yet_verified')
        };
        dispatch(coreActions.setTimer());
        dispatch({ type: types.VERIFY_EMAIL.REQUEST });
        return api.verifyEmail()
            .then(res => {
                if (res.data.verified === true) {
                    dispatch(startLogin());
                    dispatch({ type: types.VERIFY_EMAIL.SUCCESS, payload: { toast: isToast && successToast } });
                    return dispatch(setLoginInformationAfterVerification({
                        token: global.token,
                        programName: global.systemSubdomain,
                        registration: ''
                    })).then(
                        () => dispatch(updateUser({ currentRegistrationStep: USER_REGISTRATION_STEPS.none }))
                    );
                }
                dispatch({ type: types.VERIFY_EMAIL.ERROR, payload: { toast: isToast && errorToast, error: {} } });
            })
            .catch(error => {
                dispatch({ type: types.VERIFY_EMAIL.ERROR, payload: { toast: isToast && errorToast, error } });
            });
    };
}

export function updateCorporateAccountLinkData(data, replace = false) {
    return {
        type: types.UPDATE_CORPORATE_ACCOUNT_LINK_DATA,
        payload: { data, replace }
    };
}

export function updateLocalRegistrationStep(step) {
    return {
        type: types.UPDATE_LOCAL_REGISTRATION_STEP,
        payload: { step }
    };
}

export function updateLocalLoginStep(step) {
    return {
        type: types.UPDATE_LOCAL_LOGIN_STEP,
        payload: { step }
    };
}

export function resetPassword(email) {
    return async function (dispatch) {
        if (systemBuildVariant !== BUILDS.mylivebetter) {
            dispatch({ type: types.RESET_PASSWORD.REQUEST });
            await queryDirectoryInternal(dispatch, 'user', global.systemEnvironment.environment, email, true);
            // During password reset, we explicitly ignore errors to always pretend that it is working.
        }
        dispatch(resetPasswordInternal(email));
    };
}

export function resetPasswordInternal(email) {
    return function (dispatch) {
        dispatch({ type: types.RESET_PASSWORD.REQUEST });
        return api.resetPassword(email)
            .then(() => {
                dispatch({
                    type: types.RESET_PASSWORD.SUCCESS,
                    payload: { toast: { msg: i18n.t('passwordResetEmailSent'), title: i18n.t('passwordResetSuccess') } }
                });
            }).catch(error => {
                dispatch({ type: types.RESET_PASSWORD.ERROR, payload: { error, isErrorStore: true } });
            });
    };
}

export function clearResetPasswordError() {
    return function (dispatch) {
        dispatch(coreActions.removeError(types.RESET_PASSWORD.NAME));
    };
}

export function changeUserPassword(oldPassword, password) {
    return function (dispatch) {
        // api.setValidateStatus(status => (status >= 200 && status < 300) || status === 400);
        dispatch({ type: types.CHANGE_PASSWORD.REQUEST });
        return api.updateUser({ oldPassword, password })
            .then(res => {
                if (res.status === 400 && (typeof res.data.errorCode) === 'string') {
                    dispatch(changeUserPasswordErrorMessage({ response: res }));
                } else if (res.status >= 200 && res.status < 300) {
                    dispatch({
                        type: types.CHANGE_PASSWORD.SUCCESS,
                        payload: {
                            toast: {
                                title: i18n.t('changePasswordToast.success.title'),
                                msg: i18n.t('changePasswordToast.success.message')
                            }
                        },
                        data: res.data
                    });
                    dispatch(logout(CHANGE_PASSWORD_LOGOUT_DELAY));
                } else {
                    dispatch(changeUserPasswordErrorMessage({ response: res }));
                }
            })
            .catch(error => {
                dispatch(changeUserPasswordErrorMessage(error));
            });
    };
}

export function changeUserPasswordErrorMessage(error) {
    return {
        type: types.CHANGE_PASSWORD.ERROR,
        payload: {
            toast: { actionType: 'changePassword', title: i18n.t('changePasswordToast.danger.title') },
            error
        }
    };
}

export function clearChangePasswordError() {
    return coreActions.removeError(types.CHANGE_PASSWORD.NAME);
}

export function emailVerification(userID, awaitingVerification, subdomain) {
    return async function (dispatch) {
        if (systemBuildVariant !== BUILDS.mylivebetter) {
            dispatch({ type: types.VERIFY_EMAIL.REQUEST });
            const programName = _.trim(_.toLower(subdomain));
            const error = await queryDirectoryInternal(dispatch, 'program', global.systemEnvironment.environment, programName, false);
            if (error) {
                dispatch({ type: types.VERIFY_EMAIL.ERROR, payload: { error } });
                return;
            }
        }
        dispatch(emailVerificationInternal(userID, awaitingVerification));
    };

}

function emailVerificationInternal(userID, awaitingVerification) {
    return function (dispatch) {
        dispatch(coreActions.setTimer());
        dispatch({ type: types.VERIFY_EMAIL.REQUEST });
        return api.emailVerificationStepOne({ user_id: userID, awaiting_verification: awaitingVerification })
            .then(response => api.emailVerificationStepTwo({ user_id: userID, awaiting_verification: awaitingVerification })
                .then(() => {
                    dispatch(startLogin());
                    dispatch({ type: types.VERIFY_EMAIL.SUCCESS, payload: {} });
                    dispatch(setLoginInformationAfterVerification({ ..._.omit(response.data, 'email'), registration: '' })).then(
                        () => dispatch(updateUser({ currentRegistrationStep: USER_REGISTRATION_STEPS.none }))
                    );
                }))
            .catch(error => {
                dispatch({ type: types.VERIFY_EMAIL.ERROR, payload: { error } });
            });
    };
}

export function completeRegistration() {
    return function (dispatch) {
        return api.completeRegistration()
            .then(() => dispatch(coreActions.retrieveCurrentUserInfoSuccess({ firstSignIn: 0 })))
            .catch(error => console.log('registration done error', error.response));
    };
}

export function initiateAppleHealthBackgroundSync(item, baseurl, deviceId) {
    return function (dispatch) {
        // It's healthkit and it's this device being connected
        // It's possible that the user login to another iOS device on the same account
        if (Platform.OS === PLATFORMS.ios) {
            healthKit.initSproutBackgroundTask({
                token: global.token,
                url: baseurl,
                vendorId: item.vendorId,
                vendorName: 'iOS Health',
                deviceId
            }, () => {
            });
        }
    };
}

export function clearAppleHealthBackgroundSync() {
    return function (dispatch) {
        if (Platform.OS === PLATFORMS.ios) {
            healthKit.clearSproutBackgroundTask({}, () => {});
        }
    };
}

export function saveLoginQueryParams(payload) {
    return {
        type: types.SAVE_LOGIN_QUERY_PARAMS,
        payload
    };
}

// Get the user's goal smart mode status: If empty, opt the user in. If false or true, do nothing
export function checkSmartModeStatus(userID) {
    return function (dispatch, getState) {
        dispatch({ type: types.CHECK_SMART_MODE_STATUS.REQUEST });
        return api.checkGoalSmartModeStatus(userID)
            .then(response => {
                dispatch({ type: types.CHECK_SMART_MODE_STATUS.SUCCESS });
                if (_.isEmpty(response.data)) {
                    dispatch(updateGoalSmartModeStatus(userID, 1));
                } else {
                    const status = _.get(response.data[0], 'value') === '1' ? true : false;
                    dispatch(coreActions.saveSmartModeStatus(status));
                }
            })
            .catch(error => {
                dispatch({ type: types.CHECK_SMART_MODE_STATUS.ERROR, payload: { error } });
            });
    };
}

export function updateGoalSmartModeStatus(userID, status) {
    return function (dispatch, getState) {
        const isLiveBetter = coreSelectors.isLiveBetter(getState());
        if (!isLiveBetter) {
            dispatch({ type: types.UPDATE_GOAL_SMART_MODE_STATUS.REQUEST });
            return api.updateGoalSmartModeStatus({ user_id: userID, value: status })
                .then(response => {
                    if (_.get(response.data, 'status') === 'success') {
                        dispatch({ type: types.UPDATE_GOAL_SMART_MODE_STATUS.SUCCESS });
                        dispatch(coreActions.saveSmartModeStatus(status));
                        dispatch(challengesActions.getPersonalGoalsAndChallenges({ isCompleted: 0, isEnded: 0, isSolo: 1, isUnstarted: 0 }));
                    }
                })
                .catch(error => {
                    dispatch({ type: types.UPDATE_GOAL_SMART_MODE_STATUS.ERROR, payload: { error } });
                });
        }
    };
}

export function checkEmailRegistrationStatus(email) {
    return async function (dispatch) {
        if (systemBuildVariant !== BUILDS.mylivebetter) {
            dispatch({ type: types.CHECK_EMAIL_REGISTRATION_STATUS.REQUEST });
            const error = await queryDirectoryInternal(dispatch, 'user', global.systemEnvironment.environment, email, true);
            if (error) {
                dispatch({
                    type: types.CHECK_EMAIL_REGISTRATION_STATUS.SUCCESS,
                    payload: { registrationStatus: USER_REGISTRATION_STATUS.notFound, ssoLoginURL: '' }
                });
                return;
            }
        }
        dispatch(checkEmailRegistrationStatusInternal(email));
    };
}

function checkEmailRegistrationStatusInternal(email) {
    return function (dispatch) {
        const formattedEmail = _.toLower(_.trim(_.replace(email, '+', '%2B')));
        dispatch({ type: types.CHECK_EMAIL_REGISTRATION_STATUS.REQUEST });
        return api.checkEmailRegistrationStatus(formattedEmail)
            .then(response => {
                const registerStatus = _.get(response.data, 'registerStatus');
                let ssoLoginURL = '';
                if (_.get(response.data, 'ssoLoginUrl')) {
                    ssoLoginURL = _.get(response.data, 'ssoLoginUrl');
                } else if (_.get(response.data, 'sso')) {
                    ssoLoginURL = _.get(response.data, 'sso');
                }
                const isTHVCSSOEnabled = _.get(response.data.configurations, 'isTHVCSSOEnabled', false);
                const subdomain = _.get(response.data, 'subdomain', undefined);
                const isHybridLoginSelection = _.get(response.data, 'hybridLogin', 0);
                let registrationStatus = USER_REGISTRATION_STATUS.notFound;
                if (registerStatus === 0 && !subdomain) {
                    registrationStatus = USER_REGISTRATION_STATUS.notFound;
                }
                else if (registerStatus === 0 && ssoLoginURL.length === 0) {
                    registrationStatus = USER_REGISTRATION_STATUS.notRegistered;
                }
                else if (registerStatus === 0 && ssoLoginURL.length > 0) {
                    registrationStatus = USER_REGISTRATION_STATUS.notRegisteredSSO;
                }
                else if (registerStatus === 1 && ssoLoginURL.length > 0) {
                    registrationStatus = USER_REGISTRATION_STATUS.registeredSSO;
                }
                else if (registerStatus === 1 && ssoLoginURL.length === 0) {
                    if (isTHVCSSOEnabled) {
                        registrationStatus = USER_REGISTRATION_STATUS.registeredTHVC;
                    } else {
                        registrationStatus = USER_REGISTRATION_STATUS.registered;
                    }
                }
                
                if (isHybridLoginSelection) {
                    registrationStatus = USER_REGISTRATION_STATUS.hybridLogin;
                }

                if (subdomain) {
                    dispatch(getProgramConfigurations({ programName: subdomain, skipSubdomainSave: false }))
                        .then(() => {
                            dispatch({ type: types.CHECK_EMAIL_REGISTRATION_STATUS.SUCCESS, payload: { registrationStatus, ssoLoginURL, email } });
                        });
                } else {
                    dispatch({ type: types.CHECK_EMAIL_REGISTRATION_STATUS.SUCCESS, payload: { registrationStatus, ssoLoginURL, email } });
                }
            })
            .catch(error => {
                dispatch({ type: types.CHECK_EMAIL_REGISTRATION_STATUS.ERROR, payload: { toast: { title: i18n.t('signin') }, error } });
            });
    };
}

export function clearRegistrationStatus() {
    return function (dispatch) {
        dispatch({ type: types.CLEAR_EMAIL_REGISTRATION_STATUS, payload: { data: '' } });
    };
}

export function checkEmployeeIDExists(employeeId) {
    return function (dispatch, getState) {
        const companyId = _.get(selectors.getCompany(getState()), 'companyId');
        if (companyId && employeeId) {
            dispatch({ type: types.CHECK_EMPLOYEE_ID.REQUEST });
            return api.checkEmployeeIDExists(companyId, employeeId)
                .then(response => {
                    dispatch({ type: types.CHECK_EMPLOYEE_ID.SUCCESS, payload: { status: HRIS_ID_STATUS.exists, employeeId } });
                })
                .catch(error => {
                    dispatch({ type: types.CHECK_EMPLOYEE_ID.ERROR, payload: { status: HRIS_ID_STATUS.doesnot_exist } });
                });
        }
    };
}

export function clearEmployeeIDExistsError() {
    return function (dispatch) {
        dispatch({ type: types.CHECK_EMPLOYEE_ID.ERROR, payload: { status: '' } });
    };
}

export function getHRISLocations() {
    return function (dispatch, getState) {
        const companyId = selectors.getCompanyId(getState());
        if (companyId) {
            dispatch({ type: types.GET_HRIS_LOCATIONS.REQUEST });
            return api.getHRISLocations(companyId)
                .then(res => {
                    dispatch({ type: types.GET_HRIS_LOCATIONS.SUCCESS, payload: { data: res.data } });
                })
                .catch(error => {
                    dispatch({ type: types.GET_HRIS_LOCATIONS.ERROR, payload: { error } });
                });
        }
    };
}

export function setHRISLocation(location) {
    return function (dispatch, getState) {
        dispatch({ type: types.SET_HRIS_LOCATION, payload: { hrisLocation: location, registrationFields: location.registrationFields } });
    };
}

export function getCompanyExternalApps() {
    return function (dispatch, getState) {
        dispatch({ type: types.GET_COMPANY_EXTERNAL_APPS.REQUEST });
        return api.getCompanyExternalApps()
            .then(res => {
                dispatch({ type: types.GET_COMPANY_EXTERNAL_APPS.SUCCESS, payload: { externalApps: res.data } });
            })
            .catch(error => {
                dispatch({ type: types.GET_COMPANY_EXTERNAL_APPS.ERROR, payload: { error } });
            });
    };
}